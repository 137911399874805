import { environment } from '@environments/environment';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { ActivitiesTierbaseTabModel, ActivitiesTierItemMasterModel } from '@app/models/commission-contract.model';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { Guid } from '@app/models/guid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { CommissionService } from '@app/services/contract-service/commission/commission.service';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';

@Component({
	selector: 'app-commission-tierbase-activities-tab',
	templateUrl: './commission-tierbase-activities-tab.component.html',
	styleUrls: ['./commission-tierbase-activities-tab.component.css'],
})
export class CommissionTierbaseActivitiesTabComponent implements OnInit {
	@Input() activitiesTabList: any = [];
	@Output() saveCommissionActivitiesEvent = new EventEmitter<string>();
	public guid = new Guid();
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	public erp_type: string;
	public isCurrentTabPermission: string;
	public isDisablePermission: boolean = false;
	public permsn: any = [];

	public editCommissionGuid: string;
	public getUomLstSbsn: Subscription;
	public listOfUoms: any[] = [];
	public listOfUomGroups: any[] = [];
	public getUomGroupLstSbsn: Subscription;

	public lengthSelectedActivities: any;
	public lengthSelectedItems: any;
	public arrSelectedActivitiesList: any = [];
	public activitiesDataSource: any;
	public activitiesGridBoxValue: any[] = [];
	public activitiesGridBoxColumns: any[] = [];
	public chkAllActivities: boolean = false;
	public dataSourceActivitiesLstTab: any[] = [];
	public decimalPointForCurrency: number;
	@ViewChild("tiersItemDetailssDataGridRef", {}) tiersItemDetailssDataGridRef: DxDataGridComponent;
	@ViewChild("activitiesDataGrid", {}) activitiesDataGrid: DxDataGridComponent;
	@ViewChild("activitiesTabDataGridRef", {}) activitiesTabDataGridRef: DxDataGridComponent;

	constructor(
		private _PermissionsService: NgxPermissionsService,
		private _ToastrService: ToastrService,
		private _RestApiService: RestApiService,
		private _LoginService: LoginService,
		private _LoaderService: LoaderService,
		private _CommissionService: CommissionService,
		private _CustomCurrencyPipe: CustomCurrencyPipe
	) { }

	ngOnInit() {
		if (this.activitiesTabList && this.activitiesTabList.length > 0) {
			this.dataSourceActivitiesLstTab = this.activitiesTabList || [];
		}
		// Check Edit Mode
		this.editCommissionGuid = this._CommissionService.getEditCommissionGuid();
		// Get current permissions
		const permissions = this._PermissionsService.getPermissions();
		for (const per in permissions) {
			this.permsn.push(per);
		}

		if (this.permsn.indexOf('INCENTIVE_COMMISSION_MODIFY') === -1) {
			this.isDisablePermission = true;
			this.isCurrentTabPermission = 'INCENTIVE_COMMISSION_MODIFY';
		}
		this.preInit();
	}

	preInit() {
		this.isQBOnlineErpUser = this._CommissionService.getQbOnlineErpUser();
		this.isQBErpUser = this._CommissionService.getQbErpUser();
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;

		this.activitiesGridBoxColumns = [{ dataField: 'guid', caption: 'Guid', visible: false }, { dataField: 'label', caption: 'Activity' }, { dataField: 'value', caption: 'Value', visible: false }];
		this.loadActivitiesDataList();
		this.fetchListOfUoms();
		this.fetchListOfUomGroups();
		this.loadActivitiesDataList();
	}

	// #region tof Fetch List of Uom(s)

	// Method used to get Uom List
	private fetchListOfUoms() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.UOM);
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name']));
		formData.append('is_dropdown', 'true');

		this._LoaderService.show();
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		this.getUomLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUoms = response.data;
					}
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to get Uo Group List
	private fetchListOfUomGroups() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'uomgroups');
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name', 'baseuom', 'uomgroupdefinitioncollection']));
		formData.append('is_dropdown', 'true');

		this._LoaderService.show();
		if (this.getUomGroupLstSbsn) {
			this.getUomGroupLstSbsn.unsubscribe();
		}
		this.getUomGroupLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUomGroups = response.data;
					}
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// #endregion

	//#region for Activities select box
	loadActivitiesDataList() {
		const viewFields = ["guid", "property_guid", "value", "label", "sequence", "color", "bgcolor"];
		const searchArr = [];
		searchArr.push({ 'property_guid': '5201e2f16ac652490ead7876aac11019' });
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.GET_ENTITY_DATA,
			entity: ServerEntity.DYNAMIC_PROPERTY_OPTIONS,
			is_dropdown: true,
			search: encodeURIComponent(JSON.stringify(searchArr)),
			view_fields: JSON.stringify(viewFields)
		};
		this.activitiesDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: 'label',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}
	onActivitiesValueChanged(e: any) {
		if (e.value === null) {
			this.arrSelectedActivitiesList = [];
			this.activitiesGridBoxValue = [];
			this.activitiesDataGrid.instance.refresh();
		}
	}
	checkAllActivities() {
		// if (this.chkAllActivities) {
		// 	this.lengthSelectedActivities = 'All Activities Selected';
		// 	this.arrSelectedActivitiesList = [];
		// 	this.activitiesDataGrid.instance.refresh();
		// } else {
		// 	this.lengthSelectedActivities = '';
		// }
	}
	onActivitiesSelectionChanged(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			// this.chkAllActivities = false;
			this.lengthSelectedActivities = e.selectedRowsData.length + ' Activities Selected';
			this.activitiesGridBoxValue = [];
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.activitiesGridBoxValue.push(e.selectedRowsData[i].code);
			};
		}
		if (e.selectedRowsData && e.selectedRowsData.length === 0) {
			this.lengthSelectedActivities = '';
		}
	}

	//#endregion

	public addActivitiesTabDetails() {

		const selectedActivitiesList = this.activitiesDataGrid.instance.getSelectedRowsData() || [];
		if (selectedActivitiesList.length > 0) {
			selectedActivitiesList.forEach(selectActivities => {
				if (this.checkDuplicateActivities(selectActivities)) {
					const activitiesModel = new ActivitiesTierbaseTabModel();
					activitiesModel.guid = this.guid.newGuid();
					activitiesModel.activities_guid = selectActivities.guid;
					activitiesModel.activities_label = selectActivities.label;
					activitiesModel.activities_value = selectActivities.value;
					activitiesModel.activities_tiers_items = [];
					this.activitiesTabList.unshift(activitiesModel);
				}
			});
			setTimeout(() => {
				this.dataSourceActivitiesLstTab = this.activitiesTabList;
			}, 50);
			if (this.editCommissionGuid) {
				setTimeout(() => {
					this.saveCommissionActivitiesEvent.emit('save');
				}, 700);
			}
			this.arrSelectedActivitiesList = [];
			this.activitiesGridBoxValue = [];
			this.activitiesDataGrid.instance.refresh();
			this.lengthSelectedActivities = '';
		}
	}

	public checkDuplicateActivities(activities) {
		if (this.activitiesTabList && this.activitiesTabList.length > 0) {
			const activitieIndex = this.activitiesTabList.findIndex(item => item.activities_guid === activities.guid);
			if (activitieIndex !== -1) {
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	}

	//#region Tiears item grid method
	public getTiersByItemDetails(activities) {
		return activities.data.activities_tiers_items || [];
	}
	public doOnInitNewRowTiersItmGrpTab(event: any, element: any) {
		let tierObject = new ActivitiesTierItemMasterModel();
		tierObject.guid = this.guid.newGuid();
		const itemDetails = element.data;
		event.data = tierObject;
	}
	public doOnRowInsertingTiersItmGrpTab(event: any, element: any) {
		const itemDetails = element.data;
		const listOfRows = event.component.getVisibleRows();
		const rowIndex = event.component.getRowIndexByKey(event.data.guid);
		let tierDetails: any;
		if (rowIndex !== -1) {
			tierDetails = listOfRows[rowIndex].data;
		} else {
			tierDetails = event.data;
		}
	}
	public doOnEditorPreparingTiersItmGrpTab(event: any, element: any) {

	}
	public doOnRowRemovedTiersItmGrpTab(event: any, element: any) {
		if (this.editCommissionGuid) {
			setTimeout(() => {
				this.saveCommissionActivitiesEvent.emit('save');
			}, 700);
		}
	}
	public doOnSavedTiersItmGrpTab(event: any, element: any) {
		if (this.editCommissionGuid) {
			setTimeout(() => {
				this.saveCommissionActivitiesEvent.emit('save');
			}, 700);
		}
	}
	//#endregion 

	//#region Activities grid method
	public doToolbarPreparing(e) {
		e.toolbarOptions.items.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					text: "Export",
					onClick: (event: any) => {
						this.doExportToExcel(event);
					}
				}
			}
		);
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel(e) {
		if (this.activitiesTabDataGridRef.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'ItemAndGroupTab.xlsx';
		worksheet = workbook.addWorksheet('ItemAndGroup');
		const listOfMasterDetailsRows: any[] = [];
		exportDataGrid({
			worksheet: worksheet,
			component: this.activitiesTabDataGridRef.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
			}
		}).then((cellRange) => {
			const borderStyle = { style: "thin", color: { argb: "FF7E7E7E" } };
			let offset = 0;

			const insertRow = (index: number, rowOffset: any, outlineLevel: any) => {
				const currentIndex = index + rowOffset;
				const row = worksheet.insertRow(currentIndex, [], "n");
				for (let j = worksheet.rowCount + 1; j > currentIndex; j--) {
					worksheet.getRow(j).outlineLevel = worksheet.getRow(j - 1).outlineLevel;
				}
				row.outlineLevel = outlineLevel;
				return row;
			};

			let columnsField = [
				'mintier',
				'maxtier',
				'rate'
			];
			let columns = [
				'Tier Minimum',
				'Tier Maximum',
				'Rate'
			];

			let rebate_basis;
			for (let i = 0; i < listOfMasterDetailsRows.length; i++) {
				rebate_basis = listOfMasterDetailsRows[i].data.rebate_basis;
				columnsField = [
					'mintier',
					'maxtier',
					'rate'
				];
				columns = [
					'Tier Minimum',
					'Tier Maximum',
					'Rate',
				];

				let row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
				const columnIndex = cellRange.from.column;
				// row.height = 24;
				// worksheet.mergeCells(row.number, columnIndex, row.number, 7);

				if (listOfMasterDetailsRows[i].data.tiers.length > 0) {
					row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);

					columns.forEach((columnName, currentColumnIndex) => {
						Object.assign(row.getCell(columnIndex + currentColumnIndex), {
							value: columnName,
							fill: {
								type: "pattern",
								pattern: "solid",
								fgColor: { argb: "BEDFE6" }
							},
							font: { bold: true },
							border: {
								bottom: borderStyle,
								left: borderStyle,
								right: borderStyle,
								top: borderStyle
							}
						});
					});

					let subTotalLineTotal = 0;
					listOfMasterDetailsRows[i].data.tiers.forEach((tierDetails) => {
						row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
						columnsField.forEach((columnName, currentColumnIndex) => {
							const cellObject = {
								value: tierDetails[columnName],
								fill: {
									type: "pattern",
									pattern: "solid",
									fgColor: { argb: "BEDFE6" }
								},
								border: {
									bottom: borderStyle,
									left: borderStyle,
									right: borderStyle,
									top: borderStyle
								},
								alignment: { horizontal: 'left' }
							};

							if (columnName === 'mintier') {
								cellObject.value = this._CommissionService.convertCurrency(tierDetails[columnName]);
								cellObject.alignment.horizontal = 'right';
							}
							if (columnName === 'maxtier') {
								cellObject.value = this._CommissionService.convertCurrency(tierDetails[columnName]);
								cellObject.alignment.horizontal = 'right';
							}

							Object.assign(row.getCell(columnIndex + currentColumnIndex), cellObject);
						});
					});

					row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
					let rowLastCell: number;

					worksheet.mergeCells(row.number, columnIndex, row.number, 2);
					rowLastCell = 3;
					Object.assign(row.getCell(columnIndex), {
						value: 'Sub Total',
						fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } },
						font: { bold: true },
						alignment: { horizontal: 'right' }
					});

					Object.assign(row.getCell(rowLastCell), {
						value: this._CommissionService.convertCurrency(subTotalLineTotal),
						fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } },
						font: { bold: true },
						border: {
							bottom: borderStyle,
							left: borderStyle,
							right: borderStyle,
							top: borderStyle
						},
						alignment: { horizontal: 'right' }
					});

					row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
				}
				offset--;
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
		e.cancel = true;
	}

	public doOnEditorPreparingActivitiesTab(event: any) {

	}
	public doOnRowUpdatedActivitiesTab(event: any) {

	}
	public doOnRowRemovedActivitiesTab(event: any) {
		if (this.editCommissionGuid) {
			setTimeout(() => {
				this.saveCommissionActivitiesEvent.emit('save');
			}, 700);
		}
	}
	public doOnSavedActivitiesTab(event: any) {
		if (this.editCommissionGuid) {
			setTimeout(() => {
				this.saveCommissionActivitiesEvent.emit('save');
			}, 700);
		}
	}
	public doOnRowPreparedActivitiesTab(event: any) {

	}
	public doOnRowExpandingItmGrpTab(event: any) {

	}
	//#endregion
}

