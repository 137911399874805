import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnDestroy, AfterViewInit, SimpleChanges } from '@angular/core';
import { CommissionService } from '@app/services/contract-service/commission/commission.service';
import { LoginService } from '@app/services/login.service';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { CommissionApprovalStatusValEnum, ContractTypeValEnum } from '@app/constants-enums/enums';
import { CommissionContractModelRequest, CommissionContractModelUI, CommissionSettlemntRptModel } from '@app/models/commission-contract.model';
import { DxDataGridComponent } from 'devextreme-angular';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { environment } from '@environments/environment';
import { CurrencyOptions, PageOptions } from '@app/models/common.model';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { Observable, Subscription } from 'rxjs';
import moment from 'moment';

@Component({
	selector: 'app-commission-tierbase-close-settlement-list-tab',
	templateUrl: './commission-tierbase-close-settlement-list-tab.component.html',
	styleUrls: ['./commission-tierbase-close-settlement-list-tab.component.css']
})

export class CommissionTierbaseCloseSettlementListTabComponent implements OnInit, OnDestroy {
	@Input() commissionDetailsProps: any;
	public commissionDetails: any = new CommissionContractModelUI();
	@Input() salesPplList: any = [];
	@Input() isAllCustomerSelectedView: boolean = false;
	@Input() isAllProductSelectedView: boolean = false;
	@Input() customerTabList: any = [];
	@Input() productOrGroupTabList: any = [];
	@Input() exclusionTabList: any = [];
	@Input() ruleTabList: any[] = [];
	@Input() commissionRequest = new CommissionContractModelRequest();
	@Input() isCurrentTabPermission: string;
	@ViewChild('dataGridContainer') dataGridContainer: DxDataGridComponent;
	@Output() closedSettlementListEvent = new EventEmitter<string>();
	public pageOptions: PageOptions = new PageOptions();
	private dataOutReqSubscription: Subscription;
	private getFeatureCustomFieldsLstSbn: Subscription;
	public closeSettlementDataSource: any = {};
	public filterText: string = '';
	private filterValueChangeSbsn: Observable<any>;
	private isDisabledClearBtn: boolean = true;
	private instanceClearBtn: any = null;
	private listOfFeatureCustomFields: any;
	public decimalPointForCurrency: number;
	public globalCurrencySymbol: string;
	public isShowLoaderIndicator: boolean = false;
	public updateSettlementPeriodBtnText = 'Update Current Settlement Period';
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();
	public enable_other_currencies: boolean = false;
	public enable_incentive_accrual_journal_entry: boolean = false;
	public listOfApprovalStatuses: any[] = [];
	public enable_commission_settlement_approval_workflow: boolean;
	public isShowApprovalStatusColumn: boolean;
	public CommissionApprovalStatusValEnum = CommissionApprovalStatusValEnum;
	public approvalTrailDetailsProps: any;
	private isCellClickApprovalTrail: boolean;
	public isShowApprovalTrailsPopup: boolean;

	constructor(private _Router: Router,
		public _AppCommonSrvc: AppCommonSrvc,
		private _LoginService: LoginService,
		private _CommissionService: CommissionService,
		private _ToastrService: ToastrService,
		private _RestApiService: RestApiService
	) {
		this.doCustomizeTotalSummary = this.doCustomizeTotalSummary.bind(this);
	}

	ngOnInit() {
		// this.globalCurrencySymbol = this._CommissionService.globalCurrencySymbol;
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_other_currencies')) {
			this.enable_other_currencies = this._LoginService.loginUser.account_detail.enable_other_currencies;
		}
		if (this.commissionDetails.currency_code) {
			this.customCurrencyOption = this._CommissionService.getCustomCurrencyOption(this.commissionDetails);
			this.globalCurrencySymbol = this._CommissionService.globalCurrencySymbol;
		} else {
			this.globalCurrencySymbol = this._CommissionService.globalCurrencySymbol;
		}
		this.enable_incentive_accrual_journal_entry = this._AppCommonSrvc.getEnableIncentiveAccualJournalEntery();
		this.doGetSettlementPeriodList();
		this.preInIt();
	}

	ngOnChanges(_SimpleChanges: SimpleChanges) {
		if (_SimpleChanges['commissionDetailsProps']) {
			const commissionDetailsProps = _SimpleChanges['commissionDetailsProps'].currentValue;
			this.commissionDetails = commissionDetailsProps;
		}
	}

	ngAfterViewinit() {
	}

	ngOnDestroy() {
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.getFeatureCustomFieldsLstSbn) {
			this.getFeatureCustomFieldsLstSbn.unsubscribe();
		}
	}

	// Method used to Init Tab and load data.
	private preInIt() {
		this.listOfApprovalStatuses = this._CommissionService.getListOfApprovalStatuses;
		this.doCheckEnabledCommissionSettlementApprovalWorkflowFeature();
		this.doHideShowDatagridColumns();
		setTimeout(() => {
			this.filterValueChangeSbsn = this.dataGridContainer.filterValueChange.asObservable();
			this.filterValueChangeSbsn.subscribe((result) => {
				this.getFilterText();
			});
		}, 60);
		this.doGetFeatureCustomFields();
	}

	doGetSettlementPeriodList() {
		const editCommissionGuid = this._CommissionService.getEditCommissionGuid();
		const viewFields = ['number', 'contractName', 'settlementPeriodStart', 'settlementPeriodEnd', 'subusername', 'settlementValue', 'apInvoiceCreatedInERP'];
		let queryString = environment.URL_DATAOUT;
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			baseContractCode: editCommissionGuid,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.CLOSESETTLEMENTS,
			contracttype: ContractTypeValEnum.COMMISSIONCONTRACTS,
		};
		// loadParams.view_fields =  JSON.stringify(viewFields);
		this.closeSettlementDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
		});
	}

	public doCellClickSettlementDetails(e: any) {
		this.isCellClickApprovalTrail = false;
		if (e.column.dataField === "guid") {
			this.isCellClickApprovalTrail = true;
			return;
		}
	}

	public doRowClickSettlementDetails(invoiceDetails) {
		if (this.isCellClickApprovalTrail) {
			return;
		}
		if (invoiceDetails) {
			// const isMordenViewRpt: boolean = false;
			const isMordenViewRpt: boolean = this._AppCommonSrvc.checkCommissionReportMordenView(ContractTypeValEnum.COMMISSIONCONTRACTS);
			if (isMordenViewRpt) {
				let commissionSettlemntRptModel: CommissionSettlemntRptModel = new CommissionSettlemntRptModel();
				commissionSettlemntRptModel.contractGuid = invoiceDetails.guid;
				commissionSettlemntRptModel.baseContractGuid = this.commissionDetails.guid;
				commissionSettlemntRptModel.ixcode = invoiceDetails.ixcode;
				commissionSettlemntRptModel.contractName = invoiceDetails.contractName;
				commissionSettlemntRptModel.currency_code = this.commissionDetails.currency_code;
				commissionSettlemntRptModel.calculate_commission_by = this.commissionDetails.calculate_commission_by;
				commissionSettlemntRptModel.salesRep = invoiceDetails.subusername;
				commissionSettlemntRptModel.calculationOnBasis = invoiceDetails.calculationOnBasis;
				commissionSettlemntRptModel.settlementPeriodStartDate = invoiceDetails.settlementPeriodStart;
				commissionSettlemntRptModel.settlementPeriodEndDate = invoiceDetails.settlementPeriodEnd;
				commissionSettlemntRptModel.created_date = invoiceDetails.created_date;
				commissionSettlemntRptModel.apInvoiceCreatedInERP = invoiceDetails.apInvoiceCreatedInERP;
				commissionSettlemntRptModel.remark = invoiceDetails.errorMessage;
				commissionSettlemntRptModel.totalCommissionGenerated = invoiceDetails.totalCommissionGenerated;
				commissionSettlemntRptModel.totalCommissionPayable = invoiceDetails.settlementValue;
				commissionSettlemntRptModel.subusername = invoiceDetails.subusername;
				commissionSettlemntRptModel.apInvoiceNumber = invoiceDetails.apInvoiceNumber;
				commissionSettlemntRptModel.currencyFormat = this._AppCommonSrvc.currencyFormat(this.commissionDetails.currency_code);
				if (this.enable_commission_settlement_approval_workflow) {
					commissionSettlemntRptModel.approval_status = invoiceDetails.approval_status;
				}
				this._CommissionService.setCommissionRptDetails(commissionSettlemntRptModel);
				this._Router.navigate(['/contracts/commission/modern-report']);
			} else {
				this._CommissionService.setInvoiceDetails(invoiceDetails);
				this._CommissionService.setCommissionContractDetails(this.commissionDetails);
				this._Router.navigate(['/contracts/commission/report']);
			}
		}
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		e.toolbarOptions.items.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'cleariconix',
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.dataGridContainer.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.doOpenFilterBuilder();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					text: "Export",
					onClick: (event: any) => {
						this.doExportToExcel(event);
					}
				}
			}
		);
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel(e) {
		if (this.dataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		const cName = this.commissionDetails.contractName || '';
		let fileName = '';
		if (cName) {
			fileName = cName + '-';
		}
		fileName = fileName + 'Settlement_Report.xlsx';
		worksheet = workbook.addWorksheet('Settlement List');

		exportDataGrid({
			worksheet: worksheet,
			component: this.dataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'apInvoiceCreatedInERP') {
						excelCell.value = '';
						if (gridCell.value) {
							let colorName;
							switch (gridCell.value) {
								case 'Open':
									colorName = '706cce';
									break;
								case 'Processing':
									colorName = 'f99300';
									break;
								case 'Success':
									colorName = '05c1a0';
									break;
								case 'Error':
									colorName = 'ff4545';
									break;
								default:
									break;
							}
							excelCell.font = { color: { argb: colorName } };
							// excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: colorName.toString() } };
							excelCell.value = gridCell.value;
						}
					}
					if (gridCell.column.dataField === 'errorMessage') {
						if (gridCell.value) {
							excelCell.alignment = { wrapText: true };
							excelCell.value = gridCell.value;
						}
					}

				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
		e.cancel = true;
	}

	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.dataGridContainer.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			this.isDisabledClearBtn = false;
		};
		this.updateStatesClearBtn();
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}

	// Method used to Open Filter Build Popup manually
	public doOpenFilterBuilder() {
		this.dataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}

	UpdateSettlementPeriodClick() {
		//this._LoaderService.show();
		this.isShowLoaderIndicator = true;
		const arryContracts = [];
		// Get sub user name as per id
		if (this.commissionDetails.subuserguid && !this.commissionDetails.subusername) {
			// const emp = this.salesPplList.filter(item => (item.code).toString() === (this.commissionDetails.subuserguid).toString())[0];
			// this.commissionDetails.subusername = emp !== undefined ? emp.firstname || '' : '';
			const emp = this.salesPplList.filter(item => (item.sub_account_id).toString() === (this.commissionDetails.subuserguid).toString())[0];
			this.commissionDetails.subusername = emp !== undefined ? emp.first_name + ' ' + emp.last_name || '' : '';
		}

		this.commissionRequest.contractName = this.commissionDetails.contractName;
		this.commissionRequest.subuserguid = this.commissionDetails.subuserguid;
		this.commissionRequest.subusername = this.commissionDetails.subusername;
		this.commissionRequest.startDate = moment(this.commissionDetails.startDate).format('YYYY-MM-DD');
		this.commissionRequest.endDate = moment(this.commissionDetails.endDate).format('YYYY-MM-DD');
		this.commissionRequest.settlementPeriodStart = moment(this.commissionDetails.settlementPeriodStart).format('YYYY-MM-DD');
		this.commissionRequest.settlementPeriodEnd = moment(this.commissionDetails.settlementPeriodEnd).format('YYYY-MM-DD');
		this.commissionRequest.settlementPeriod = this.commissionDetails.settlementPeriod;
		this.commissionRequest.calculationOnBasis = this.commissionDetails.calculationOnBasis;
		this.commissionRequest.agreementBy = this.commissionDetails.agreementBy;
		this.commissionRequest.customer_items_groups = this.exclusionTabList;
		this.commissionRequest.rules = this.ruleTabList;
		this.commissionRequest.is_active = this.commissionDetails.is_active;

		arryContracts.push(this.commissionRequest);
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.UPDATE_CURRENT_SETTELEMENT_PERIOD);
		formData.append('contractguid', this.commissionDetails.guid);
		formData.append('contracttype', 'commissioncontracts');
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}

		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe({
			next: (response) => {
				//this._LoaderService.hide();
				this.isShowLoaderIndicator = false;
				if (response.flag) {
					this.commissionDetails.current_settlement_invoice_total = response.data.current_settlement_invoice_total;
					this.commissionDetails.current_settlement_commission_total = response.data.current_settlement_commission_total;
					this.doGetSettlementPeriodList();
					// this._ToastrService.success(response.message, 'Saved', { closeButton: true, tapToDismiss: true });
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this.isShowLoaderIndicator = false;
				//this._LoaderService.hide();
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	setClosedSettlementList() {
		this.closedSettlementListEvent.emit(this.closeSettlementDataSource)
	}

	// Method used to get Custom fileds for invoice
	private doGetFeatureCustomFields() {
		// const invoicesDataGridId: any = document.getElementById('invoicesDataGrid_' + indexInvoice);
		// const invoicesDataGridInstance = DxDataGrid.getInstance(invoicesDataGridId) as DxDataGrid;
		const feature_guid = this._CommissionService.getFeatureGuid() || '';
		if (!feature_guid) {
			return false;
		}
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.FEATURE_CUSTOM_FIELDS);
		formData.append('search', JSON.stringify([{ feature_guid: feature_guid }]));
		formData.append('is_dropdown', 'true');
		if (this.getFeatureCustomFieldsLstSbn) {
			this.getFeatureCustomFieldsLstSbn.unsubscribe();
		}
		this.getFeatureCustomFieldsLstSbn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
						this.listOfFeatureCustomFields = response.data;
						this._CommissionService.setlistOfFeatureCustomFields(this.listOfFeatureCustomFields);
						// if (response.data && response.data.length > 0) {
						// 	const customFieldDataList = this._CommissionService.getCustomFieldForInvoice(response.data);
						// 	// let customFieldDataObj: any;
						// 	// let customFieldDataList: any[] = [];
						// 	// this.listOfFeatureCustomFields.forEach(element => {
						// 	// 	customFieldDataObj = {
						// 	// 		dataField: element.ix_custom_field,
						// 	// 		caption: element.custom_field_title,
						// 	// 		dataType: element.custom_field_type,
						// 	// 		visible: element.display,
						// 	// 	};
						// 	// 	if (element.custom_field_type === 'object') {
						// 	// 		customFieldDataObj['lookup'] = { dataSource: element.custom_field_values, displayExpr: "value", valueExpr: "key" }
						// 	// 	}
						// 	// 	if (element.custom_field_type === 'date') {
						// 	// 		customFieldDataObj['format'] = 'yyyy-MM-dd';
						// 	// 	}
						// 	// 	customFieldDataList.push(customFieldDataObj)
						// 	// });
						// 	customFieldDataList.forEach(element => {
						// 		invoicesDataGridId.columns.push(element);
						// 	});
						// 	invoicesDataGridInstance.refresh();
						// }
					}
				}
			}, error: (error) => {
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});

	}

	public doCustomizeTotalSummary(e) {
		if (e.value < 0) {
			return this._CommissionService.convertCurrency(0, this.customCurrencyOption);
		} else {
			return this._CommissionService.convertCurrency(e.valueText, this.customCurrencyOption);
		}
	}

	public dataFieldCaption(dataField) {
		let captionTxt: string;
		if (dataField === 'settlementValue') {
			if (this.enable_incentive_accrual_journal_entry) {
				captionTxt = 'Payable Amount (' + this.globalCurrencySymbol + ')';
			} else {
				captionTxt = 'Commission Amount (' + this.globalCurrencySymbol + ')';
			}
		}
		if (dataField === 'apInvoiceCreatedInERP') {
			if (this.enable_incentive_accrual_journal_entry) {
				captionTxt = 'Payment JE Status';
			} else {
				captionTxt = 'Status';
			}
		}
		if (dataField === 'errorMessage') {
			if (this.enable_incentive_accrual_journal_entry) {
				captionTxt = 'Payment JE Remark';
			} else {
				captionTxt = 'Remark';
			}
		}
		return captionTxt;
	}

	private doHideShowDatagridColumns() {
		this.isShowApprovalStatusColumn = false;
		if (this.enable_commission_settlement_approval_workflow) {
			this.isShowApprovalStatusColumn = true;
		}
	}

	private doCheckEnabledCommissionSettlementApprovalWorkflowFeature() {
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('enable_commission_settlement_approval_workflow')) {
			this.enable_commission_settlement_approval_workflow = this._LoginService.loginUser.account_detail.enable_commission_settlement_approval_workflow;
		}
	}

	public doViewApprovalTrails(element: any) {
		this.approvalTrailDetailsProps = {
			guid: this.commissionDetails.guid,
			settlement_guid: element.guid
		}
		this.doOpenApprovalTrailsPopup();
	}


	private doOpenApprovalTrailsPopup() {
		this.isShowApprovalTrailsPopup = false;
		setTimeout(() => {
			this.isShowApprovalTrailsPopup = true;
		}, 100);
	}

	public doCloseApprovalTrailsPopup(event: any) {
		this.isShowApprovalTrailsPopup = false;
	}
}
