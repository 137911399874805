<div class="rightBar">
	<div class="rightBlock">
		<div class="borderBox cf padB20">
			<div class="titleRow">
				<h2 class="popTitle">
					<strong class="purple">{{ (invoiceDetails.apInvoiceNumber) ? (invoiceDetails.apInvoiceNumber + ' - ' +
						invoiceDetails.selectVendor + ' | ') : (invoiceDetails.selectVendor) ? invoiceDetails.selectVendor + '
						| ' : ''}}
					</strong>
					Royalty
					Calculation
				</h2>
			</div>
			<div id="exportContainer" class="right">
				<dx-button *ngIf="invoiceDetails.apInvoiceCreatedInERP !== 'Open'" class="secondaryBtn mrgR20"
					text="Export as PDF" icon="exportpdf" (onClick)="doExporToPDF()"></dx-button>
				<dx-button class="secondaryBtn" text="Export as Excel" icon="xlsxfile"
					(onClick)="onExporting($event)"></dx-button>
			</div>

			<dx-data-grid #royaltyReportDetailsDatagridContainer id="gridContainer" [dataSource]="mainDataSource"
				keyExpr="key" [remoteOperations]="true" [showBorders]="true" [allowColumnResizing]="true"
				[columnAutoWidth]="true" [width]="'100%'">
				<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
				<dxi-column dataField="name" dataType="string"> </dxi-column>

				<dxo-summary>
					<dxi-total-item column="name" alignment="left" displayFormat="Total Royalty Payable : {{invoiceDetails.settlementValue || 0 | customCurrencyPipe }}     Total
									Royalty Generated : {{invoiceDetails.totalCommissionGenerated || 0 | customCurrencyPipe }}">
					</dxi-total-item>
				</dxo-summary>

				<dxo-master-detail [enabled]="true" [autoExpandAll]="true" template="masterDetail"></dxo-master-detail>
				<div *dxTemplate="let masterDetailItem of 'masterDetail'">
					<div *ngIf="checkMasterDetails(masterDetailItem,RoyaltiesSettlementReportSectionEnum.INVOICES)">
						<dx-data-grid #invoicesDataGrid id="invoicesDataGrid" [dataSource]="invoicesDataSource"
							keyExpr="invoiceCode" [showBorders]="true" [showColumnLines]="true" [showRowLines]="false"
							[allowColumnResizing]="true" [allowColumnReordering]="true" [filterSyncEnabled]="true"
							[remoteOperations]="true" (onToolbarPreparing)="doToolbarColumnChooserBtnPreparing($event)"
							[columnAutoWidth]="true" [width]="'100%'">
							<dxo-master-detail [enabled]="true" [autoExpandAll]="false"
								template="itemDetailsTemplate"></dxo-master-detail>
							<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
								[useNative]="false"></dxo-scrolling>
							<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
							<dxo-pager [visible]="true" [showPageSizeSelector]="true"
								[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
							</dxo-pager>
							<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
							<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadInvoiceState"
								[customSave]="doSaveInvoiceState" storageKey="invoices-datagrid-state">
							</dxo-state-storing>
							<dxo-filter-row [visible]="true"></dxo-filter-row>
							<!-- <dxi-column dataField="fiscal_sequence" [groupIndex]="1" [allowSorting]="true" sortOrder="asc"
								[autoExpandGroup]="false" caption="Period"
								[calculateCellValue]="fullNameColumn_calculateCellValue">
							</dxi-column> -->
							<!-- <dxi-column dataField="invoiceDate" sortOrder="asc" dataType="date"></dxi-column> -->
							<!-- <dxi-column dataField="invoiceCode" [allowSorting]="true" [autoExpandGroup]="false"
								caption="Invoice"></dxi-column> -->
							<dxi-column dataField="clientName" caption="Customer Name" dataType="string"></dxi-column>
							<dxi-column dataField="customer_age" caption="Customer Age" [visible]="false" dataType="string">
							</dxi-column>
							<dxi-column dataField="linetotal" [allowFiltering]="false" [allowSorting]="false"
								caption="Gross Revenue" cellTemplate="cellTemplateGrossRevenu" dataType="number">
								<div *dxTemplate="let element of 'cellTemplateGrossRevenu'">
									{{element.data.linetotal || 0 | customCurrencyPipe }}
								</div>
							</dxi-column>
							<dxi-column dataField="cashdiscount" [allowFiltering]="false" [allowSorting]="false"
								caption="Cash Discount" cellTemplate="cellTemplateCashDiscount" dataType="number">
								<div *dxTemplate="let element of 'cellTemplateCashDiscount'">
									{{element.data.cashdiscount || 0 | customCurrencyPipe }}
								</div>
							</dxi-column>
							<dxi-column dataField="creditmemototal" [allowFiltering]="false" [allowSorting]="false"
								caption="Credit Memos (Item Type)" cellTemplate="cellTemplateCreditMemos" dataType="number">
								<div *dxTemplate="let element of 'cellTemplateCreditMemos'">
									{{element.data.creditmemototal || 0 | customCurrencyPipe }}
								</div>
							</dxi-column>
							<dxi-column dataField="miscDedAmount" [allowFiltering]="false" [allowSorting]="false"
								caption="Misc.Deduction" cellTemplate="cellTemplateMiscDeductionAmount" dataType="number">
								<div *dxTemplate="let element of 'cellTemplateMiscDeductionAmount'">
									{{element.data.miscDedAmount || 0 | customCurrencyPipe }}
								</div>
							</dxi-column>
							<dxi-column dataField="miscDedFcAmount" [allowFiltering]="false" [allowSorting]="false"
								caption="Misc.Deduction Fixed Cost" cellTemplate="cellTemplateMiscDeductionAmountFixedCost"
								dataType="number">
								<div *dxTemplate="let element of 'cellTemplateMiscDeductionAmountFixedCost'">
									{{element.data.miscDedFcAmount || 0 | customCurrencyPipe }}
								</div>
							</dxi-column>

							<dxi-column dataField="finalAmount" [allowFiltering]="false" [allowSorting]="false"
								caption="Net Revenue" cellTemplate="cellTemplateFinalAmount" dataType="number">
								<div *dxTemplate="let element of 'cellTemplateFinalAmount'">
									{{element.data.finalAmount || 0 | customCurrencyPipe }}
								</div>
							</dxi-column>
							<dxi-column dataField="commissionAmount" [allowFiltering]="false" [allowSorting]="false"
								caption="Royalty" [width]="250" cellTemplate="cellTemplateCommissionAmount" dataType="number">
								<div *dxTemplate="let element of 'cellTemplateCommissionAmount'">
									{{element.data.commissionAmount || 0 | customCurrencyPipe }}
								</div>
							</dxi-column>

							<dxo-summary>
								<dxi-total-item column="linetotal" summaryType="sum" displayFormat="{0}">
									<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format>
								</dxi-total-item>
								<dxi-total-item column="cashdiscount" summaryType="sum" displayFormat="{0}">
									<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format>
								</dxi-total-item>
								<dxi-total-item column="creditmemototal" summaryType="sum" displayFormat="{0}">
									<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format>
								</dxi-total-item>
								<dxi-total-item column="miscDedAmount" summaryType="sum" displayFormat="{0}">
									<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format>
								</dxi-total-item>
								<dxi-total-item column="miscDedFcAmount" summaryType="sum" displayFormat="{0}">
									<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format>
								</dxi-total-item>
								<dxi-total-item column="finalAmount" summaryType="sum" displayFormat="{0}">
									<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format>
								</dxi-total-item>
								<dxi-total-item column="commissionAmount" summaryType="sum" displayFormat="Royalty Total: {0}"
									[customizeText]="doCustomizeTotalSummary">
									<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format>
								</dxi-total-item>
							</dxo-summary>

							<!--
							<dxo-summary>
								 <dxi-group-item column="invoiceCode" summaryType="count" displayFormat="invoice {0}">
								</dxi-group-item>
								<dxi-group-item column="commissionAmount" summaryType="sum" valueFormat="$ #,##0.00"
									displayFormat="Total: {0}" [showInGroupFooter]="true">
								</dxi-group-item> 
								<dxi-total-item column="commissionAmount" summaryType="sum" valueFormat="$ #,##0.00"
									displayFormat="Sub Total: {0}">
								</dxi-total-item>
							</dxo-summary>
							-->
							<!-- <dxi-sort-by-group-summary-info summaryItem="count"></dxi-sort-by-group-summary-info> -->

							<div *dxTemplate="let itemsDetails of 'itemDetailsTemplate'">
								<dx-data-grid #invoicesItemDataGrid id="invoicesItemDataGrid"
									[dataSource]="itemsDetails.data.items" keyExpr="erp_primary_key" [showBorders]="true"
									[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
									[allowColumnReordering]="true" [filterSyncEnabled]="true" [remoteOperations]="true"
									[columnAutoWidth]="true" (onToolbarPreparing)="doToolbarColumnChooserBtnPreparing($event)"
									[width]="'100%'">
									<dxo-master-detail [enabled]="true" [autoExpandAll]="false"
										template="invoiceDetailsTemplate"></dxo-master-detail>
									<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
										[useNative]="false"></dxo-scrolling>
									<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
									<dxo-pager [visible]="true" [showPageSizeSelector]="true"
										[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
									</dxo-pager>
									<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
									<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadInvoicItemState"
										[customSave]="doSaveInvoiceItemState" storageKey="invoices-items-datagrid-state">
									</dxo-state-storing>
									<dxo-filter-row [visible]="true"></dxo-filter-row>
									<dxi-column *ngIf="isQBOnlineErpUser" dataField="itemsku" caption="SKU"
										dataType="string"></dxi-column>
									<dxi-column dataField="classname" [visible]="enable_classification_for_commision"
										[showInColumnChooser]="enable_classification_for_commision" caption="Class"
										dataType="string"></dxi-column>
									<dxi-column dataField="itemname" [visible]="!enable_classification_for_commision"
										[showInColumnChooser]="!enable_classification_for_commision" caption="Item"
										dataType="string">
									</dxi-column>
									<dxi-column dataField="description" [visible]="!enable_classification_for_commision"
										[showInColumnChooser]="!enable_classification_for_commision" caption="Description"
										dataType="string">
									</dxi-column>
									<dxi-column dataField="commissionAmount" [allowFiltering]="false" [allowSorting]="false"
										caption="Royalty" [width]="250" dataType="number">
										<dxo-format type="currency" [precision]="decimalPointForCurrency">
										</dxo-format>
										<div *dxTemplate="let element of 'cellCommissionAmount'">
											{{element.data.commissionAmount || 0 | customCurrencyPipe}}
										</div>
									</dxi-column>
									<!-- <dxo-summary>
																	<dxi-total-item column="commissionAmount" summaryType="sum" valueFormat="$ #,##0.00"
																		displayFormat="Commission Total: {0}" [customizeText]="doCustomizeTotalSummary">
																	</dxi-total-item>
																</dxo-summary> -->
									<div *dxTemplate="let invoice of 'invoiceDetailsTemplate';index as i">
										<dx-data-grid #invoicesDataGrid id="invoicesDataGrid" [dataSource]="invoice.data.invoices"
											keyExpr="invoiceCode" [showBorders]="true" [showColumnLines]="true"
											[showRowLines]="false" [allowColumnResizing]="true" [allowColumnReordering]="true"
											[filterSyncEnabled]="true" (onCellClick)="doInvoiceDetailsCellClick($event)"
											(onToolbarPreparing)="doToolbarColumnChooserBtnPreparing($event)"
											[remoteOperations]="true" [columnAutoWidth]="true">
											<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
												[useNative]="false"></dxo-scrolling>
											<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
											<dxo-pager [visible]="true" [showPageSizeSelector]="true"
												[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
											</dxo-pager>
											<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
											<dxo-state-storing [enabled]="true" type="custom"
												[customLoad]="doLoadInvoicItemInvoiceState"
												[customSave]="doSaveInvoiceItemInvoiceState"
												storageKey="invoices-items-invoices-datagrid-state">
											</dxo-state-storing>
											<dxo-filter-row [visible]="true"></dxo-filter-row>
											<dxi-column dataField="document_type" [allowSorting]="true" caption="Doc. Type"
												[allowFiltering]="false" [allowHeaderFiltering]="true"
												cellTemplate="cellTemplateDocType" dataType="string">
												<dxo-header-filter [dataSource]="listOfDocTypeHeaderFilter">
												</dxo-header-filter>
												<div *dxTemplate="let element of 'cellTemplateDocType'">
													{{element.data.document_type | filterFromList:listOfDocTypeHeaderFilter : 'value'
													: 'text' }}
												</div>
											</dxi-column>

											<dxi-column dataField="itemname" [allowSorting]="true"
												[visible]="enable_classification_for_commision" caption="Items"
												[showInColumnChooser]="enable_classification_for_commision"
												[allowHeaderFiltering]="false" dataType="string">
											</dxi-column>

											<dxi-column dataField="invoiceCode" [allowSorting]="true" caption="Doc. No"
												[allowHeaderFiltering]="false" dataType="string" cellTemplate="cellTemplateDocNo">
												<div *dxTemplate="let element of 'cellTemplateDocNo'">
													<span class="highlight pointerMark">{{element.data.invoiceCode}}</span>
												</div>
											</dxi-column>

											<dxi-column dataField="uomcode" caption="Doc. UOM" cellTemplate="cellTemplateUom"
												[allowFiltering]="false" [allowHeaderFiltering]="true" [allowSorting]="true"
												dataType="string">
												<dxo-header-filter [dataSource]="listOfUomsHeaderFilter">
												</dxo-header-filter>
												<div *dxTemplate="let element of 'cellTemplateUom'">
													{{element.data.uomcode || '' | filterFromList:listOfUoms : 'code' : 'name' |
													uppercase }}
												</div>
											</dxi-column>

											<dxi-column dataField="quantity" caption="Doc. Qty" dataType="number"
												cellTemplate="cellTemplateQuantity" [allowHeaderFiltering]="false">
												<div *dxTemplate="let element of 'cellTemplateQuantity'">
													{{element.data.quantity || 0 }}
												</div>
											</dxi-column>

											<dxi-column dataField="contract_selectedUom" caption="Contract UOM"
												[visible]="invoice.data.commission_basis === 'dollarperuom'"
												[showInColumnChooser]="invoice.data.commission_basis === 'dollarperuom'"
												cellTemplate="cellTemplateContractUom" [allowFiltering]="false"
												[allowHeaderFiltering]="true" [allowSorting]="true" dataType="string">
												<dxo-header-filter [dataSource]="listOfUomsHeaderFilter">
												</dxo-header-filter>
												<div *dxTemplate="let element of 'cellTemplateContractUom'">
													{{element.data.contract_selectedUom || '' | filterFromList:listOfUoms :'code' :
													'name' |
													uppercase }}
												</div>
											</dxi-column>

											<dxi-column dataField="contract_quantity" caption="Contract Qty" dataType="number"
												[visible]="invoice.data.commission_basis === 'dollarperuom'"
												[showInColumnChooser]="invoice.data.commission_basis === 'dollarperuom'"
												cellTemplate="cellTemplateContractQty" [allowHeaderFiltering]="false">
												<div *dxTemplate="let element of 'cellTemplateContractQty'">
													{{element.data.contract_quantity || 0}}
												</div>
											</dxi-column>

											<dxi-column dataField="tier_basis" caption="Royalty Type"
												cellTemplate="cellTemplateTierBasis" [allowFiltering]="false"
												[allowHeaderFiltering]="true" [allowSorting]="true" dataType="string">
												<dxo-header-filter [dataSource]="listOfTierBasisHeaderFilter">
												</dxo-header-filter>
												<div *dxTemplate="let element of 'cellTemplateTierBasis'">
													{{element.data.tier_basis || '' | filterFromList:listOfTierBasis : 'code' :
													'label' | uppercase}}
												</div>
											</dxi-column>

											<dxi-column dataField="commission_basis" caption="Royalty Basis"
												cellTemplate="cellTemplateCommissionBasis" [allowFiltering]="false"
												[allowHeaderFiltering]="true" [allowSorting]="true" dataType="string">
												<dxo-header-filter [dataSource]="listOfRoyaltyBasisHeaderFilter">
												</dxo-header-filter>
												<div *dxTemplate="let element of 'cellTemplateCommissionBasis'">
													{{element.data.commission_basis || '' | filterFromList:listOfRoyaltyBasis :
													'code' : 'label' | uppercase}}
												</div>
											</dxi-column>

											<dxi-column dataField="commissionRate" [allowFiltering]="false" [allowSorting]="false"
												caption="Royalty Rate (% | $)" alignment="center" [allowHeaderFiltering]="false"
												cellTemplate="cellTemplateCommitionsRate" dataType="number">
												<div *dxTemplate="let element of 'cellTemplateCommitionsRate'">
													<span *ngIf="element.data.commissionRate">
														<span *ngIf="element.data.commissionRateIn === '$'">
															{{element.data.commissionRate || 0 | customCurrencyPipe}}
														</span>
														<span *ngIf="element.data.commissionRateIn === '%'">
															{{element.data.commissionRate + element.data.commissionRateIn}}
														</span>
													</span>
												</div>
											</dxi-column>

											<dxi-column dataField="condition" caption="Condition" dataType="string"></dxi-column>

											<dxi-column dataField="criterion" caption="Criterion" dataType="string"></dxi-column>

											<dxi-column dataField="linetotal" caption="Line Total ({{globalCurrencySymbol}})"
												dataType="number" [allowFiltering]="false" [allowSorting]="false"
												[allowHeaderFiltering]="false">
												<dxo-format type="currency" [precision]="decimalPointForCurrency">
												</dxo-format>
												<div *dxTemplate="let element of 'cellTemplateLineTotal'">
													{{element.data.linetotal || 0 | customCurrencyPipe }}
												</div>
											</dxi-column>

											<!-- 
												<dxi-column dataField="miscdeductioninventory" [allowFiltering]="false"
													[allowSorting]="false" caption="Misc. Deduction (%)" dataType="number"
													[visible]="invoice.data.commission_basis === 'percentage'"
													cellTemplate="cellTemplateMiscDeduction" [allowHeaderFiltering]="false">
													<div *dxTemplate="let element of 'cellTemplateMiscDeduction'">
														{{element.data.miscdeductioninventory || 0  }}%
													</div>
												</dxi-column> 
											-->

											<dxi-column dataField="miscDedAmount" [allowFiltering]="false" [allowSorting]="false"
												caption="Misc. Deduction" dataType="number"
												[visible]="invoice.data.commission_basis === 'percentage'"
												[showInColumnChooser]="invoice.data.commission_basis === 'percentage'"
												cellTemplate="cellTemplateMiscDedAmount" [allowHeaderFiltering]="false">
												<div *dxTemplate="let element of 'cellTemplateMiscDedAmount'">
													{{element.data.miscDedAmount || 0 | customCurrencyPipe}}
													({{element.data.miscdeductioninventory || 0 }}%)
												</div>
											</dxi-column>
											<dxi-column dataField="miscDedFcAmount" [allowFiltering]="false" [allowSorting]="false"
												caption="Misc. Deduction Fixed Cost" dataType="number"
												[visible]="invoice.data.commission_basis === 'percentage'"
												[showInColumnChooser]="invoice.data.commission_basis === 'percentage'"
												[allowHeaderFiltering]="false">
												<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
												</dxo-value-format>
												<div *dxTemplate="let element of 'cellTemplateMiscDeductionAmountFixedCost'">
													{{element.data.miscDedFcAmount || 0 | customCurrencyPipe}}
												</div>
											</dxi-column>

											<dxi-column dataField="cashdiscount" [allowFiltering]="false" [allowSorting]="false"
												caption="Cash Discount ({{globalCurrencySymbol}})" dataType="number"
												[visible]="invoice.data.commission_basis === 'percentage'"
												[showInColumnChooser]="invoice.data.commission_basis === 'percentage'"
												[allowHeaderFiltering]="false">
												<dxo-format type="currency" [precision]="decimalPointForCurrency">
												</dxo-format>
												<div *dxTemplate="let element of 'cellTemplateCashDiscount'">
													{{element.data.cashdiscount || 0 | customCurrencyPipe }}
												</div>
											</dxi-column>
											<dxi-column dataField="finalAmount" [allowFiltering]="false" [allowSorting]="false"
												caption="Final Line Total ({{globalCurrencySymbol}})" dataType="number"
												[visible]="invoice.data.commission_basis === 'percentage'"
												[showInColumnChooser]="invoice.data.commission_basis === 'percentage'"
												[allowHeaderFiltering]="false">
												<dxo-format type="currency" [precision]="decimalPointForCurrency">
												</dxo-format>
												<div *dxTemplate="let element of 'cellTemplateFinalAmount'">
													{{element.data.finalAmount || 0 | customCurrencyPipe }}
												</div>
											</dxi-column>
											<dxi-column dataField="costtotal" [allowFiltering]="false" [allowSorting]="false"
												[visible]="invoiceDetails.calculationOnBasis === 'grossprofit'"
												caption="Cost ({{globalCurrencySymbol}})"
												[showInColumnChooser]="invoiceDetails.calculationOnBasis === 'grossprofit'"
												dataType="number">
												<dxo-format type="currency" [precision]="decimalPointForCurrency">
												</dxo-format>
												<div *dxTemplate="let element of 'cellCostTotal'">
													{{element.data.costtotal || 0 | customCurrencyPipe}}
												</div>
											</dxi-column>
											<dxi-column dataField="paymentParcent " [allowFiltering]="false" [allowSorting]="false"
												caption="Payment (%)" cellTemplate="cellPaymentParcent" dataType="number">
												<div *dxTemplate="let element of 'cellPaymentParcent'">
													{{element.data.paymentParcent || 0 | number:decimalPointPercentageFormat}}
												</div>
											</dxi-column>
											<dxi-column dataField="grossprofit" [allowFiltering]="false" [allowSorting]="false"
												[visible]="invoiceDetails.calculationOnBasis === 'grossprofit'"
												caption="Profit ({{globalCurrencySymbol}})"
												[showInColumnChooser]="invoiceDetails.calculationOnBasis === 'grossprofit'"
												dataType="number">
												<dxo-format type="currency" [precision]="decimalPointForCurrency">
												</dxo-format>
												<div *dxTemplate="let element of 'cellGrossProfit'">
													{{element.data.grossprofit || 0 | customCurrencyPipe}}
												</div>
											</dxi-column>
											<dxi-column dataField="commissionAmount" [allowFiltering]="false"
												[allowSorting]="false" caption="Royalty" dataType="number">
												<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
												</dxo-value-format>
												<div *dxTemplate="let element of 'cellCommissionAmount'">
													{{element.data.commissionAmount || 0 | customCurrencyPipe}}
												</div>
											</dxi-column>
										</dx-data-grid>
									</div>
								</dx-data-grid>
							</div>
						</dx-data-grid>
					</div>

					<div
						*ngIf="checkMasterDetails(masterDetailItem,RoyaltiesSettlementReportSectionEnum.EXTRA_ROYALTIES_BASED_RULES)">
						<dx-data-grid #extraRoyaltiesBasedRulesDataGrid id="gridContainer"
							[dataSource]="extraRoyaltiesBasedRulesDataSource" keyExpr="criterion" [showBorders]="true"
							[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
							[allowColumnReordering]="true" (onToolbarPreparing)="doToolbarColumnChooserBtnPreparing($event)"
							[filterSyncEnabled]="true" [remoteOperations]="true" [columnAutoWidth]="true" [width]="'100%'">
							<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
								[useNative]="false"></dxo-scrolling>
							<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
							<dxo-pager [visible]="true" [showPageSizeSelector]="true"
								[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
							</dxo-pager>
							<dxo-filter-row [visible]="true"></dxo-filter-row>
							<dxo-sorting mode="single"></dxo-sorting>
							<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
							<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadExtraRoyaltiesBasedRuleState"
								[customSave]="doSaveExtraRoyaltiesBasedRuleState" storageKey="invoices-datagrid-state">
							</dxo-state-storing>
							<dxi-column dataField="commissionType" caption="Royalty Type" dataType="string"></dxi-column>
							<dxi-column dataField="condition" caption="Condition" dataType="string"></dxi-column>
							<dxi-column dataField="criterion" caption="Criterion" dataType="string"></dxi-column>
							<dxi-column dataField="royaltiesRate" alignment="center" caption="Royalty Rate (%)"
								dataType="number">
							</dxi-column>
							<dxi-column dataField="commissionAmount" caption="Royalty Amount ({{globalCurrencySymbol}})"
								cellTemplate="cellTemplateRoyaltiesAmount" [width]="180" dataType="number">
								<div *dxTemplate="let element of 'cellTemplateRoyaltiesAmount'">
									{{element.data.commissionAmount || 0 | customCurrencyPipe }}
								</div>
							</dxi-column>
							<dxo-summary>
								<dxi-total-item column="commissionAmount" summaryType="sum" displayFormat="Sub Total: {0}">
									<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format>
								</dxi-total-item>
							</dxo-summary>
						</dx-data-grid>
					</div>

					<div *ngIf="checkMasterDetails(masterDetailItem,RoyaltiesSettlementReportSectionEnum.AR_CREDIT_MEMOS)">
						<dx-data-grid #arcreditmemosDataGrid id="gridContainer" [dataSource]="arcreditmemosDataSource"
							keyExpr="clientCode" [showBorders]="true" [showColumnLines]="true" [showRowLines]="false"
							[allowColumnResizing]="true" [allowColumnReordering]="true" [filterSyncEnabled]="true"
							[remoteOperations]="true" (onToolbarPreparing)="doToolbarColumnChooserBtnPreparing($event)"
							[columnAutoWidth]="true" [width]="'100%'">
							<dxo-master-detail [enabled]="true" [autoExpandAll]="false"
								template="itemServiceCraditMemoDetailsTemplate"></dxo-master-detail>
							<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
								[useNative]="false"></dxo-scrolling>
							<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
							<dxo-pager [visible]="true" [showPageSizeSelector]="true"
								[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
							</dxo-pager>
							<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
							<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadArCreditMemoState"
								[customSave]="doSaveArCreditMemoState" storageKey="invoices-datagrid-state">
							</dxo-state-storing>
							<dxo-filter-row [visible]="true"></dxo-filter-row>
							<dxi-column dataField="invoiceCode" dataType="string" [groupIndex]="1" [allowSorting]="true"
								[autoExpandGroup]="false" caption="Invoice"></dxi-column>
							<dxi-column dataField="clientName" dataType="string" caption="Customer Name"></dxi-column>
							<dxi-column dataField="doctotal" dataType="number" caption="Doc Total"
								cellTemplate="cellTemplateClientDocTotal">
								<div *dxTemplate="let element of 'cellTemplateClientDocTotal'">
									{{element.data.doctotal || 0 | customCurrencyPipe }}
								</div>
							</dxi-column>
							<dxi-column *ngIf="isQBOnlineErpUser" dataField="itemsku" dataType="string"
								caption="Item"></dxi-column>
							<dxi-column dataField="itemname" dataType="string" caption="Item"></dxi-column>
							<dxi-column dataField="description" dataType="string" caption="Description"></dxi-column>
							<dxi-column dataField="linetotal" dataType="number" caption="Line Total ({{globalCurrencySymbol}})"
								cellTemplate="cellTemplateLineTotal">
								<div *dxTemplate="let element of 'cellTemplateLineTotal'">
									{{element.data.linetotal || 0 | customCurrencyPipe }}
								</div>
							</dxi-column>
							<dxi-column dataField="royaltiesType" dataType="string" caption="Royalties Type"></dxi-column>
							<dxi-column dataField="condition" dataType="string" caption="Condition"></dxi-column>
							<dxi-column dataField="criterion" dataType="string" caption="Criterion"></dxi-column>
							<dxi-column dataField="royaltiesRate" dataType="number" alignment="center"
								caption="Royalty Rate (%)">
							</dxi-column>
							<dxi-column dataField="commissionAmount" dataType="number"
								caption="Royalty Amount ({{globalCurrencySymbol}})" cellTemplate="cellTemplateRoyaltiesAmount"
								[width]="180">
								<div *dxTemplate="let element of 'cellTemplateRoyaltiesAmount'">
									{{element.data.commissionAmount || 0 | customCurrencyPipe }}
								</div>
							</dxi-column>
							<dxo-summary>
								<dxi-total-item column="commissionAmount" summaryType="sum" displayFormat=" Sub Total: {0}">
									<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format>
								</dxi-total-item>
							</dxo-summary>

							<div *dxTemplate="let itemsDetails of 'itemServiceCraditMemoDetailsTemplate'">
								<dx-data-grid #serviceCraditMemoDataGrid id="serviceCraditMemoDataGrid"
									[dataSource]="itemsDetails.data.creditmemos" keyExpr="docentry" [showBorders]="true"
									[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
									[filterSyncEnabled]="true" [remoteOperations]="true" [columnAutoWidth]="true"
									[width]="'100%'">
									<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
										[useNative]="false"></dxo-scrolling>
									<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
									<dxo-pager [visible]="true" [showPageSizeSelector]="true"
										[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
									</dxo-pager>
									<dxo-filter-row [visible]="true"></dxo-filter-row>

									<!-- <dxi-column dataField="doctype" caption="Doc Type">
																										</dxi-column> -->
									<dxi-column dataField="docnum" dataType="string" caption="Doc. Num">
									</dxi-column>
									<!-- <dxi-column dataField="docentry" caption="Doc Entery">
																										</dxi-column> -->
									<dxi-column dataField="docdate" dataType="date" caption="Doc. Date">
									</dxi-column>
									<!-- <dxi-column dataField="cardname" caption="Card Name">
																										</dxi-column> -->
									<dxi-column dataField="doctotal" dataType="number" caption="Doc. Total">
										<dxo-format type="currency" [precision]="decimalPointForCurrency">
										</dxo-format>
										<div *dxTemplate="let element of 'cellTemplateDocTotal'">
											{{element.data.doctotal || 0 | customCurrencyPipe }}
										</div>
									</dxi-column>
								</dx-data-grid>
							</div>

						</dx-data-grid>
					</div>

					<div
						*ngIf="checkMasterDetails(masterDetailItem,RoyaltiesSettlementReportSectionEnum.AMORTIZED_PAYMENTS)">
						<dx-data-grid #amortizedPaymentsDataGrid id="gridContainer" [dataSource]="amortizedPaymentsDataSource"
							keyExpr="docnum" [showBorders]="true" [showColumnLines]="true" [showRowLines]="false"
							[allowColumnResizing]="true" [dateSerializationFormat]="'yyyy-MM-dd'"
							[allowColumnReordering]="true" [filterSyncEnabled]="true" [remoteOperations]="true"
							(onCellClick)="doInvoiceDetailsCellClick($event)"
							(onToolbarPreparing)="doToolbarColumnChooserBtnPreparing($event)" [columnAutoWidth]="true"
							[width]="'100%'">
							<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
								[useNative]="false"></dxo-scrolling>
							<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
							<dxo-pager [visible]="true" [showPageSizeSelector]="true"
								[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
							</dxo-pager>
							<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
							<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadAmortizedPaymentsState"
								[customSave]="doSaveAmortizedPaymentsState" storageKey="invoices-datagrid-state">
							</dxo-state-storing>
							<dxo-filter-row [visible]="false"></dxo-filter-row>
							<dxo-header-filter [visible]="false"></dxo-header-filter>
							<dxo-sorting mode="single"></dxo-sorting>

							<dxi-column dataField="settlement_close_date" dataType="date" format="yyyy-MM-dd"
								caption="Payment Start" [width]="'auto'">
							</dxi-column>

							<dxi-column dataField="condition" dataType="string" caption="Payment #"
								cellTemplate="cellTemplatePayment" [width]="'auto'">
								<div *dxTemplate="let element of 'cellTemplatePayment'">
									{{element.data.current_payment}} of {{element.data.number_of_payments}}
								</div>
							</dxi-column>


							<dxi-column dataField="document_type" dataType="string" [allowSorting]="true" caption="Doc. Type"
								[allowFiltering]="false" [allowHeaderFiltering]="true" cellTemplate="cellTemplateDocType"
								[width]="'auto'">
								<dxo-header-filter [dataSource]="listOfDocTypeHeaderFilter">
								</dxo-header-filter>
								<div *dxTemplate="let element of 'cellTemplateDocType'">
									{{element.data.document_type | filterFromList:listOfDocTypeHeaderFilter : 'value'
									: 'text' }}
								</div>
							</dxi-column>

							<dxi-column dataField="docnum" dataType="string" caption="Doc. No" [width]="'auto'"
								cellTemplate="cellTemplateDocNo">
								<div *dxTemplate="let element of 'cellTemplateDocNo'">
									<span class="highlight pointerMark">{{element.data.docnum}}</span>
								</div>
							</dxi-column>

							<dxi-column dataField="billing_start_date" dataType="date" caption="Billing Start Date"
								format="yyyy-MM-dd" [width]="'auto'">
							</dxi-column>

							<dxi-column dataField="billing_end_date" dataType="date" caption="Billing End Date"
								format="yyyy-MM-dd" [width]="'auto'">
							</dxi-column>

							<dxi-column dataField="total_commission" dataType="number" caption="Total Royalty Amount"
								[width]="'auto'">
								<dxo-format type="currency" [precision]="decimalPointForCurrency">
								</dxo-format>
							</dxi-column>

							<dxi-column dataField="payment_amount" dataType="number" caption="Amortized Amount"
								[width]="'auto'">
								<dxo-format type="currency" [precision]="decimalPointForCurrency">
								</dxo-format>
							</dxi-column>

							<dxo-summary>
								<dxi-total-item column="total_commission" summaryType="sum" displayFormat="{0}">
									<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format>
								</dxi-total-item>
								<dxi-total-item column="payment_amount" summaryType="sum" displayFormat="Amortized Total: {0}">
									<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format>
								</dxi-total-item>
							</dxo-summary>
						</dx-data-grid>
					</div>
				</div>
				<dxo-export [enabled]="false"></dxo-export>
			</dx-data-grid>
		</div>
	</div>
</div>

<app-view-invoice-detail-popup *ngIf="isShowInvoiceDetailPopup" [viewInvoiceDetailProps]="viewInvoiceDetailProps">
</app-view-invoice-detail-popup>