<div class="rightBar" id="commissioncontract">
	<div class="rightBlock">
		<div class="tabbing cf" *ngIf="editCommissionGuid && !editCommissionTemplateGuid">
			<ul>
				<li (click)="clickMainTab('settlement_period')" [ngClass]="{active: currentMainTab == 'settlement_period'}">
					<a>Settlement Report</a>
				</li>
				<li (click)="clickMainTab('commission_details')"
					[ngClass]="{active: currentMainTab == 'commission_details'}">
					<a>Commission Details</a>
				</li>
			</ul>
		</div>

		<!--Commission details content -->
		<div *ngIf="currentMainTab === 'commission_details'">
			<div class="titleRow">
				<!-- <h1> {{editCommissionGuid ? 'Update' : 'Add New'}} Commission Agreement</h1> -->
			</div>
			<div class="contentRow cf">
				<div class="borderBox cf padB0">
					<!-- <div class="subTitleRow">
          <h2>Commission Details</h2>
        </div> -->
					<div class="formControls editAgreeDetail">
						<div class="row">
							<div [ngClass]="contractNameUIClass">
								<div class="dx-field mrgT10 mrgB10">
									<div class="dx-field-label">Contract Name</div>
									<div class="dx-field-value">
										<dx-text-box mode="text" [(value)]="commissionDetails.contractName"
											[disabled]="isDisablePermission" [showClearButton]="true">
										</dx-text-box>
									</div>
								</div>
							</div>
							<div class="cols3" *ngIf="enable_other_currencies">
								<div class="dx-field mrgT10">
									<div class="dx-field-label">Currency</div>
									<div class="dx-field-value" style="width: 100%;">
										<dx-lookup [items]="listOfOtherCurrency" [displayExpr]="displayExprForCurrency"
											[value]="commissionDetails.currency_code" valueExpr="currency_code"
											[searchEnabled]="false" [searchPlaceholder]="''" [applyValueMode]="'instantly'"
											[cleanSearchOnOpening]="true" fieldTemplate="field" [showCancelButton]="false"
											itemTemplate="listItem" (onContentReady)="currencyLookUpContentReady($event)"
											(onValueChanged)="doSelectionChangedForCurrency($event)" [wrapItemText]="true"
											[disabled]="(isDisablePermission || isDisabledCurrencyField)"
											[elementAttr]="{ 'class': 'currencyDropdownIx' }">
											<dxo-drop-down-options title="Select Currency" [hideOnOutsideClick]="true"
												[height]="310" [maxHeight]="290" [showTitle]="false"></dxo-drop-down-options>
											<div *dxTemplate="let currency of 'field'">
												<div *ngIf="currency" class="custom-field d-flex">
													<span class="currency-flag mrgL10 mrgT3" [ngClass]="currency.icon"></span>
													<div class="flex-grow-1">
														<p class="mrgB0">{{ currency.currency_code}} -
															{{currency.erp_currency_name}}
															<span class="currencySymbol alignRight"
																[innerHtml]="currency.currency_symbol"></span>
														</p>
													</div>
												</div>
											</div>
											<div *dxTemplate="let currency of 'listItem'">
												<div class="custom-item d-flex currencyDropdownCustom-item">
													<span class="currency-flag mrgT10" [ngClass]="currency.icon"></span>
													<div class="flex-grow-1">
														<h5 class="mrgB0" [innerHtml]="currency.currency_code"></h5>
														<p class="mrgB0" [innerHtml]="currency.erp_currency_name"></p>
													</div>
													<span class="currencySymbol mrgT10"
														[innerHTML]="currency.currency_symbol"></span>
												</div>
											</div>
										</dx-lookup>
									</div>
								</div>
							</div>
							<div class="cols3 distributionChannel mrgT10" *ngIf="isEnableDistributionChannels">
								<app-distribution-channels-dx-drop-down-box
									[distributionChannelsModel]="distributionChannelsModel"></app-distribution-channels-dx-drop-down-box>
							</div>
							<div class="cols12 colsP0">
								<div class="cols3">
									<div class="dx-field labelRadio mrgT5">
										<div class="dx-field-label">Contract Type:</div>
										<div class="dx-field-value">
											<dx-radio-group [dataSource]="radioCommissionTypeDataList" displayExpr="text"
												valueExpr="value" [(value)]="commissionDetails.contract_type"
												[disabled]="isDisabledContractType || !isAddMode" layout="horizontal"
												(onValueChanged)="onCommissionTypeValueChanged($event)">
											</dx-radio-group>
										</div>
									</div>
								</div>
								<div class="cols3">
									<div class="dx-field labelRadio mrgT5">
										<div class="dx-field-label">Calculation Basis:</div>
										<div class="dx-field-value">
											<dx-radio-group [dataSource]="listOfCalculationOnBasis" displayExpr="text"
												valueExpr="value" valueChangeEvent="blur"
												[(value)]="commissionDetails.calculationOnBasis"
												[disabled]="isDisabledCalculationOnBasisType || !isAddMode" layout="horizontal">
											</dx-radio-group>
										</div>
									</div>
								</div>
								<div class="cols3">
									<div class="dx-field labelRadio mrgT5">
										<div class="dx-field-label">Customer/Item Match:</div>
										<div class="dx-field-value">
											<dx-radio-group [dataSource]="listOfCustomerItemMatch" displayExpr="text"
												valueExpr="value" valueChangeEvent="blur"
												[(value)]="commissionDetails.customer_item_match"
												[disabled]="isDisablePermission  || isDisabledCustomerItemMatch || isDisabledContractType || !isAddMode"
												layout="horizontal">
											</dx-radio-group>
										</div>
									</div>
								</div>
								<div class="cols3">
									<div class="dx-field labelRadio mrgT5">
										<div class="dx-field-label">Tiers Type:</div>
										<div class="dx-field-value">
											<dx-radio-group [dataSource]="listOfTiersType" displayExpr="text" valueExpr="value"
												valueChangeEvent="blur" [value]="commissionDetails.tiers_type"
												(onValueChanged)="doTiersTypeChanged($event)"
												[disabled]="isDisablePermission || isDisabledTierType || isDisabledContractType || !isAddMode"
												layout="horizontal">
											</dx-radio-group>
										</div>
									</div>
								</div>
							</div>
							<div class="cols12 colsP0">
								<div class="cols3" *ngIf="commissionDetails.contract_type == 'growth_incentive'">
									<div class="dx-field mrgT15 mrgB10">
										<div class="dx-field-label">Comparison Year</div>
										<div class="dx-field-value" style="width: 100%;">
											<dx-select-box [items]="listOfYears" [(value)]="commissionDetails.comparison_year">
											</dx-select-box>
										</div>
									</div>
								</div>
								<div class="cols3"
									[ngClass]="{'cols3': commissionDetails.contract_type === 'growth_incentive','cols6':commissionDetails.contract_type === 'standard'}">
									<!-- <div class="feildCol" [ngClass]="{inputDisable: isDisablePermission}">
									<select id="subuserguid" [(ngModel)]="commissionDetails.subuserguid"
										[disabled]="isDisablePermission">
										<option *ngFor="let sp of salesPplList" [ngValue]="sp.sub_account_id">
											{{sp.name || sp.first_name +' '+ sp.last_name}}
										</option>
									</select>
									<div class="inputField">
										<label for="subuserguid">Salespersons Name</label>
									</div>
								</div> -->
									<div class="dx-field mrgT15 mrgB10" [ngClass]="{'inputDisable': isDisablePermission}">
										<div class="dx-field-label">Salespersons Name</div>
										<div class="dx-field-value" style="width: 100%;">
											<dx-drop-down-box [(value)]="salesPersonGridBoxValue" valueExpr="sub_account_id"
												[displayExpr]="displayExprSalesPersonName" placeholder="Select Salespersons Name..."
												[(opened)]="isSalesPersonDropDownBoxOpened" [showClearButton]="true"
												[deferRendering]="false" [dataSource]="salesPersonDataSource"
												(onOptionChanged)="onSalesPersonSelectionChanged($event)"
												[dropDownOptions]="{width:650}">
												<div *dxTemplate="let data of 'content'">
													<dx-data-grid [dataSource]="salesPersonDataSource" keyExpr="sub_account_id"
														[columns]="salespersonGridBoxColumns" [hoverStateEnabled]="true" height="100%"
														[(selectedRowKeys)]="salesPersonGridBoxValue">
														<dxo-selection mode="single"></dxo-selection>
														<dxo-filter-row [visible]="true"></dxo-filter-row>
														<dxo-scrolling mode="virtual" [scrollByThumb]="true"
															[showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
														<dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>
													</dx-data-grid>
												</div>
											</dx-drop-down-box>
										</div>
									</div>
								</div>
								<div class="cols3">
									<div class="dx-field mrgT15 mrgB10"
										[ngClass]="{'inputDisable':isDisablePermission || !isEnabledStartDate}">
										<div class="dx-field-label">Start Date</div>
										<div class="dx-field-value">
											<dx-date-box [(value)]="commissionDetails.startDate"
												(onValueChanged)="setSettlementStartDate()" type="date" displayFormat="yyyy-MM-dd"
												[disabled]="isDisablePermission || !isEnabledStartDate">
											</dx-date-box>
										</div>
									</div>
								</div>
								<div class="cols3">
									<div class="dx-field mrgT15 mrgB10">
										<div class="dx-field-label">End Date</div>
										<div class="dx-field-value">
											<dx-date-box [(value)]="commissionDetails.endDate" (onValueChanged)="compareTwoDates()"
												type="date" displayFormat="yyyy-MM-dd" [disabled]="isDisablePermission">
											</dx-date-box>
										</div>
									</div>
								</div>
							</div>

							<div class="cols12 colsP0">
								<div class="cols2"
									*ngIf="commissionDetails.settlementPeriod !== 'monthly' && commissionDetails.settlementPeriod !== 'custom' && commissionDetails.settlementPeriod !== 'weekly' && commissionDetails.settlementPeriod !== 'biweekly'">
									<div class="dx-field mrgT15 mrgB10">
										<div class="dx-field-label">Contract Calendar</div>
										<div class="dx-field-value">
											<dx-select-box #contractCalendarSelectBoxRef [dataSource]="listOfContractCalendar"
												[(value)]="commissionDetails.contractCalendar" displayExpr="text" valueExpr="value"
												(onValueChanged)="setSettlementStartDate()" customItemCreateEvent="blur"
												placeholder="Contract Calendar">
											</dx-select-box>
										</div>
									</div>
								</div>
								<div class="cols2" *ngIf="commissionDetails.settlementPeriod !== 'custom'">
									<div class="dx-field mrgT15 mrgB10">
										<div class="dx-field-label">Start Settlement Period On</div>
										<div class="dx-field-value"
											*ngIf="commissionDetails.settlementPeriod !== 'weekly' && commissionDetails.settlementPeriod !== 'biweekly'">
											<dx-number-box [(value)]="commissionDetails.startSettlementPeriodOn"
												[disabled]="isDisablePermission" [showClearButton]="true"
												(onValueChanged)="setSettlementStartDate()" maxLength="2" [min]="1" [max]="30">
											</dx-number-box>
										</div>
										<div class="dx-field-value"
											*ngIf="commissionDetails.settlementPeriod === 'weekly' || commissionDetails.settlementPeriod === 'biweekly'">
											<dx-select-box #weekDaysSelectBoxRef [dataSource]="listOfWeekDays"
												[(value)]="commissionDetails.startSettlementPeriodWeekOfDayOn" displayExpr="text"
												(onValueChanged)="setSettlementStartDate()" valueExpr="value"
												customItemCreateEvent="blur" placeholder="Contract Calendar">
											</dx-select-box>
										</div>
									</div>
								</div>
								<div class=""
									[ngClass]="commissionDetails.settlementPeriod === 'monthly' || commissionDetails.settlementPeriod === 'custom' ? 'cols8' : 'cols7'">
									<div class="dx-field labelRadio mrgT5">
										<div class="dx-field-label">Settlement Period:</div>
										<div class="dx-field-value">
											<dx-radio-group [dataSource]="listOfSettlementPeriods" displayExpr="text"
												valueExpr="value"
												(onValueChanged)="calculateSettlementPeriod(commissionDetails);doChangeTierAccrualPeriod($event);"
												valueChangeEvent="blur" [(value)]="commissionDetails.settlementPeriod"
												[disabled]="isDisablePermission || (isClosedSettlementReport && commissionDetails.tier_mode === 'marginal')"
												layout="horizontal">
											</dx-radio-group>
										</div>
									</div>
								</div>
							</div>
							<div class="cols12 colsP0" *ngIf="enable_invoice_qualification_feature">
								<div class="cols3">
									<div class="dx-field mrgT15 mrgB10">
										<div class="dx-field-label">Invoice Qualification Start Date</div>
										<div class="dx-field-value">
											<dx-date-box [(value)]="commissionDetails.invoice_qualification_start_date" type="date"
												displayFormat="yyyy-MM-dd" [disabled]="isDisablePermission">
											</dx-date-box>
										</div>
									</div>
								</div>
								<div class="cols3">
									<div class="dx-field mrgT15 mrgB10">
										<div class="dx-field-label">Invoice Qualification End Date</div>
										<div class="dx-field-value">
											<dx-date-box [(value)]="commissionDetails.invoice_qualification_end_date" type="date"
												displayFormat="yyyy-MM-dd" [disabled]="isDisablePermission">
											</dx-date-box>
										</div>
									</div>
								</div>
							</div>
							<div class="cols12 colsP0">
								<div class="cols3" *ngIf="enable_commission_on_sales_orders_feature">
									<div class="dx-field labelRadio mrgT5">
										<div class="dx-field-label">Calculate Commission By:</div>
										<div class="dx-field-value">
											<dx-radio-group [dataSource]="radioSalesOrderOrInvoiceList" displayExpr="text"
												valueExpr="value" [(value)]="commissionDetails.calculate_commission_by"
												[disabled]="isClosedSettlementReport" layout="horizontal">
											</dx-radio-group>
										</div>
									</div>
								</div>
								<div class="cols3">
									<div class="dx-field dx-lableset mrgT5">
										<div class="dx-field-label">Tier Mode:</div>
										<div class="dx-field-value" style="width:100%">
											<dx-radio-group [dataSource]="listOfTierModes" displayExpr="text" valueExpr="value"
												[(value)]="commissionDetails.tier_mode" (onValueChanged)="doChangeTierMode($event)"
												valueChangeEvent="blur" [disabled]="isClosedSettlementReport" layout="horizontal">
											</dx-radio-group>
										</div>
									</div>
								</div>
								<div class="cols3" *ngIf="isShowTierAccrualPeriodField">
									<div class="dx-field mrgT15 mrgB10">
										<div class="dx-field-label">Tier Accrual Period</div>
										<div class="dx-field-value">
											<dx-select-box #tierAccrualPeriodSelectBoxRef [dataSource]="listOfTierAccrualPeriods"
												[(value)]="commissionDetails.tier_accrual_period" displayExpr="text"
												[disabled]="isClosedSettlementReport" valueExpr="value" [showClearButton]="true"
												placeholder="Tier Accrual Periods">
											</dx-select-box>
										</div>
									</div>
								</div>
								<div class="cols6">
									<div class="dx-field dx-lableset mrgT5">
										<div class="dx-field-label">Tiers Include:</div>
										<div class="dx-field-value" style="width: 100%;">
											<dx-radio-group [dataSource]="listOfTiersInclude" displayExpr="text" valueExpr="value"
												[(value)]="commissionDetails.tiers_include"
												[disabled]="isClosedSettlementReport || isDisabledTierInclude" layout="horizontal">
											</dx-radio-group>
										</div>
									</div>
								</div>
							</div>
							<div class="cols12 colsP0 mrgB10">
								<div class="cols5"
									*ngIf="commissionDetails.settlementPeriodStart && commissionDetails.settlementPeriodEnd && !isAllSettlmentClosed">
									<div class="remarkBx fullNote ecaRemark">
										<strong>Current Settlement Period: </strong>
										<span *ngIf="commissionDetails.startDate">
											{{ (commissionDetails.settlementPeriodStart !== null &&
											commissionDetails.settlementPeriodStart !== '' &&
											commissionDetails.settlementPeriodStart !== undefined) ?
											(commissionDetails.settlementPeriodStart | date: 'yyyy-MM-dd') : "" }}
											to
											{{ (commissionDetails.settlementPeriodEnd !== null &&
											commissionDetails.settlementPeriodEnd !== '' &&
											commissionDetails.settlementPeriodEnd
											!== undefined) ? (commissionDetails.settlementPeriodEnd | date: 'yyyy-MM-dd') : ""
											}}
										</span>

									</div>
								</div>
								<div
									[ngClass]="(commissionDetails.settlementPeriodStart && commissionDetails.settlementPeriodEnd) ? 'cols7' : 'cols12'">
									<div class="multiBtnsRow">
										<div *ngIf="!enable_commission_settlement_approval_workflow" class="btnsGroup">
											<dx-button *ngxPermissionsOnly="isCurrentTabPermission" [disabled]="isAddMode"
												class="secondaryBtn" [ngClass]="{'grayButton': isAddMode}"
												text="Close Current Settlement Period"
												(onClick)="doCloseCurrentSettlementPeriod(commissionDetails)"
												[visible]="!isAllSettlmentClosed" [useSubmitBehavior]="false">
											</dx-button>
										</div>
										<div *ngIf="enable_commission_settlement_approval_workflow && !isAddMode"
											class="btnsGroup">
											<dx-button *ngxPermissionsOnly="isCurrentTabPermission" class="secondaryBtn"
												[ngClass]="{'grayButton': isAddMode }" text="Send Current Settlement For Approval"
												(onClick)="doSendCurrentSettlementForApproval($event)"
												[disabled]="isDisabledSendCurrentSettlementForApprovalBtn"
												[useSubmitBehavior]="false">
											</dx-button>
										</div>
										<!-- <div
											*ngIf="isShowMultipleBtns && (commissionDetails.status===CommissionStatusValEnum.SIGNED || commissionDetails.status===CommissionStatusValEnum.ACTIVE)"
											class="btnsGroup">
											<dx-button (onClick)="doDownloadSignedContract()" class="secondaryBtn mrgR10"
												text="Download Signed Contract" type="normal" stylingMode="contained"
												[useSubmitBehavior]="false" icon="download">
											</dx-button>
										</div> -->
									</div>
								</div>

								<div class="cols12 colsP0 ucsCols" *ngIf="!isAddMode">
									<div class="cols12">
										<hr class="mrgT15">
									</div>
									<div class="cols5" *ngxPermissionsOnly="isCurrentTabPermission">
										<dx-button [disabled]="isAddMode" class="secondaryBtn"
											[ngClass]="{'grayButton': isAddMode}" text="Update Current Settlement Period"
											(onClick)="callUpdateSettlementPeriod()" [useSubmitBehavior]="false"
											[visible]="!isAllSettlmentClosed">
										</dx-button>
									</div>
									<div class="cols7" *ngxPermissionsOnly="isCurrentTabPermission">
										<div class="ucsData" *ngIf="!isAllSettlmentClosed">
											<label for="currentSettlementInvoiceTotal"> {{
												commissionDetails.calculate_commission_by ===
												'invoices' ? 'Invoice' : 'Order' }}
												Total:
												<span *ngIf="isShowLoadPanel" class="loadingPrice custSpinner"></span>
												<strong
													*ngIf="!isShowLoadPanel">{{commissionDetails.current_settlement_invoice_total ||
													0
													| customCurrencyPipe:customCurrencyOption }}</strong></label>
											<label for="currentSettlementCommissionTotal">Commission Total:
												<span *ngIf="isShowLoadPanel" class="loadingPrice custSpinner"></span>
												<strong
													*ngIf="!isShowLoadPanel">{{commissionDetails.current_settlement_commission_total
													|| 0
													| customCurrencyPipe:customCurrencyOption}}</strong></label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="borderBox cf">
					<div class="tabbing cf">
						<ul>
							<li *ngIf="commissionDetails.contract_type == 'standard' && !commission_rate_on_invoice"
								(click)="clickAgreementTab('exclusions')"
								[ngClass]="{active: currentAgreementTab == 'exclusions'}">
								<a *ngIf="is_use_territory_for_commission_contract">Territories</a>
								<a *ngIf="enable_classification_for_commision">Customers/Classes</a>
								<a
									*ngIf="!enable_classification_for_commision && !is_use_territory_for_commission_contract">Customers/Items</a>
							</li>

							<li *ngIf="commissionDetails.contract_type == 'growth_incentive' && !commission_rate_on_invoice"
								(click)="clickAgreementTab('growth-incentive')"
								[ngClass]="{active: currentAgreementTab == 'growth-incentive'}">
								<a>Growth Incentive</a>
							</li>
							<!-- 	<li *ngIf="commissionDetails.contract_type == 'standard'" (click)="clickAgreementTab('activities')"
									[ngClass]="{active: currentAgreementTab == 'activities'}">
			 								<a>Activities</a>
			 						</li>-->
							<li (click)="clickAgreementTab('rules')" [ngClass]="{active: currentAgreementTab == 'rules'}">
								<a>Rules</a>
							</li>
						</ul>
					</div>

					<!--#region Exclusions content -->
					<app-commission-tierbase-exclusions-tab
						(saveCommissionExclusionEvent)="saveCommissionExclusionData($event)"
						[exclusionTabList]="exclusionTabList" [commissionDetailsProps]="commissionDetails"
						*ngIf="currentAgreementTab == 'exclusions'">
					</app-commission-tierbase-exclusions-tab>
					<!--endregion-->

					<!--#region Commission Tierbase Growth Icentive tab content-->
					<app-commission-tierbase-growth-incentive-tab [commissionDetailsProps]="commissionDetails"
						(saveCommissionGrowthIncentiveEvent)="saveGrowthIncentiveData($event)"
						[growthIncentiveTabList]="growthIncentiveTabList" *ngIf="currentAgreementTab == 'growth-incentive'">
					</app-commission-tierbase-growth-incentive-tab>
					<!--endregion-->

					<!--#region Activities tab content-->
					<app-commission-tierbase-activities-tab
						(saveCommissionActivitiesEvent)="saveCommissionActivitiesData($event)"
						[activitiesTabList]="activitiesTabList" *ngIf="currentAgreementTab === 'activities'">
					</app-commission-tierbase-activities-tab>
					<!--endregion-->

					<!--#region Rules content -->
					<app-commission-tierbase-rules-tab [commissionDetailsProps]="commissionDetails"
						(saveCommissionRulesEvent)="saveCommissionRulesData($event)" [ruleTabList]="ruleTabList"
						[exclusionTabList]="exclusionTabList" [isCurrentTabPermission]="isCurrentTabPermission" *ngIf="currentAgreementTab ===
						'rules'">
					</app-commission-tierbase-rules-tab>
					<!--endregion-->

					<div class="alignRight mrgT10" *ngxPermissionsOnly="isCurrentTabPermission">
						<dx-button class="primaryBtn" type="normal" stylingMode="contained" icon="save" text="Submit"
							(onClick)="saveCommissionContract()" [useSubmitBehavior]="false">
						</dx-button>
					</div>
				</div>
			</div>
		</div>
		<!--End commission details content -->

		<!--Close settlement list-->
		<div *ngIf="currentMainTab === 'settlement_period'">
			<app-commission-tierbase-close-settlement-list-tab [commissionDetailsProps]="commissionDetails"
				[commissionRequest]="commissionRequest" [salesPplList]="salesPplList"
				[isAllProductSelectedView]="isAllProductSelectedView"
				[isAllCustomerSelectedView]="isAllCustomerSelectedView" [customerTabList]="customerTabList"
				[productOrGroupTabList]="productOrGroupTabList" [exclusionTabList]="exclusionTabList"
				[ruleTabList]="ruleTabList" [isCurrentTabPermission]="isCurrentTabPermission">
			</app-commission-tierbase-close-settlement-list-tab>
		</div>
		<!--End close settlement list-->

	</div>
</div>

<app-close-current-settlement-period-confirm-dialog *ngIf="isViewCloseCurrentSettlementPeriodPopup"
	[currentStlmtPeriodProps]="currentStlmtPeriodProps"
	(closeCurrentStlmtPeriodPopup)="doCloseCurrentStlmtPeriodPopup($event)"></app-close-current-settlement-period-confirm-dialog>