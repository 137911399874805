import { environment } from '@environments/environment';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { ErpTypeValEnum, NameFromServerKeyEnum } from '@app/constants-enums/enums';
import { CommissionContractModelUI, ExclusionTabModel, ExclusionTierbaseTabModel } from '@app/models/commission-contract.model';
import { CommissionService } from '@app/services/contract-service/commission/commission.service';
import { DataService } from '@app/services/data.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DxDataGridComponent, DxDropDownBoxComponent } from 'devextreme-angular';
//import { LoaderService } from '@app/services/loaderservices/loader.service';
import { Guid } from '@app/models/guid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';
import { TerritoryDxDropDownBoxModel } from '@app/models/input-Components.model';
import { TerritoryDxDropDownBoxComponent } from 'app/input-components/territory-dx-drop-down-box/territory-dx-drop-down-box.component';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import * as _ from 'underscore';
import { CurrencyOptions } from '@app/models/common.model';

@Component({
	selector: 'app-commission-tierbase-customer-item-marginal-tab',
	templateUrl: './commission-tierbase-customer-item-marginal-tab.component.html',
	styleUrls: ['./commission-tierbase-customer-item-marginal-tab.component.css'],
	providers: [CurrencyPipe, DecimalPipe]
})
export class CommissionTierbaseCustomerItemMarginalTabComponent implements OnInit, OnChanges, OnDestroy {
	public guid = new Guid();
	@Input() exclusionTabList: any = [];
	@Input() commissionDetailsProps: any;
	//public exclusionTabListClone: any[] = [];
	@Output() saveCommissionExclusionEvent = new EventEmitter<string>();
	@ViewChild("userListDetailssDataGridRef", {}) userListDetailssDataGridRef: DxDataGridComponent;
	@ViewChild("exclusionTabDataGridRef", {}) exclusionTabDataGridRef: DxDataGridComponent;
	@ViewChild("tiersItemDetailssDataGridRef", {}) tiersItemDetailssDataGridRef: DxDataGridComponent;
	public listOfStatusHeaderFilter: any[] = [{ value: true, text: 'Active' }, { value: false, text: 'Inactive' }];
	public isQBErpUser: boolean = false;
	public isQBOnlineErpUser: boolean = false;
	public isNetsuiteErpUser: boolean = false;
	public enable_classification_for_commision: boolean = false;
	public enable_invoice_custom_fields_feature: boolean = false;
	public isViewExlusionTabGrid: boolean = false; // use for Initialized dynamic custom column for DSS
	public enable_profit_on_document_header: boolean = false;
	public productGroupRadioButton: string;
	public erp_type: string;
	public isCustomerAgeTiers: boolean = false;
	public globalCurrency: string;
	public editCommissionGuid: string;
	public getUomLstSbsn: Subscription;
	public getFeatureCustomFieldsLstSbn: Subscription;
	public listOfUoms: any[] = [];
	public listOfUomGroups: any[] = [];
	public listOfFeatureCustomFields: any;
	public getUomGroupLstSbsn: Subscription;
	public searchTimeout: any;
	public isCurrentTabPermission: string;
	public isDisablePermission: boolean = false;
	public exclusionsGroupRadioButton: string;
	public GroupsList: any = [];
	public selectCustomerSearchTabData: any = [];
	public selectProductSearchTabData: any = [];
	public selectedCustomerData: any = [];
	public selectedCustomerGroupData: any = [];
	public selectedProductData: any = [];
	public selectedClassesData: any = [];
	public isCustomerSearchTabAllSelected: boolean = false;
	public isProductSearchTabAllSelected: boolean = false;
	public isAllProductSelectedView: boolean = false;
	public exclusionTabModel = new ExclusionTabModel();
	public dataSourceExclusionTab: any;
	public listOfCommissionBasis: any[] = [];
	public listOfTierBasis: any[] = [];
	public isDisabledClearBtn: boolean = true;
	public instanceClearBtn: any;
	public customerOrGroupTab: string = 'customers';
	// public strSelectedItemList: string;
	// public strSelectedCustomerList: string;
	@ViewChild("customerDataGrid", {}) customerDataGrid: DxDataGridComponent;
	@ViewChild("customerGroupDataGrid", {}) customerGroupDataGrid: DxDataGridComponent;
	@ViewChild("customercustomerGroupDataGrid", {}) customercustomerGroupDataGrid: DxDataGridComponent;
	@ViewChild("itemDataGrid", {}) itemDataGrid: DxDataGridComponent;
	@ViewChild("classesDataGrid", {}) classesDataGrid: DxDataGridComponent;
	public chkAllCustomer: boolean = false;
	public chkAssignedCustomer: boolean = false;
	public chkByTransaction: boolean = false;
	public chkSalesTeamTransaction: boolean = false;
	public chkAllCustomrGroup: boolean = false;
	public chkAllClasses: boolean = false;
	public chkAllItem: boolean = false;
	public chkMyCustomers: boolean = false;
	public lengthSelectedCustomersAndGroups: any;
	public lengthSelectedCustomers: any;
	public lengthSelectedGroups: any;
	public lengthSelectedItems: any;
	public lengthSelectedClasses: any;
	public arrSelectedCustomerList: any = [];
	public arrSelectedItemList: any = [];
	public arrSelectedClassesList: any = [];
	public classesDataSource: any;
	public itemDataSource: any;
	public customerDataSource: any;
	public customerGridBoxValue: any[] = [];
	public itemGridBoxValue: any[] = [];
	public classesGridBoxValue: any[] = [];
	public customerGridBoxColumns: any;
	public itemGridBoxColumns: any;
	public classesGridBoxColumns: any;
	public groupDataSource: any;
	public customerGroupGridBoxValue: any[] = [];
	public customerGroupGridBoxColumns: any;
	public arrSelectedCustomerGroupList: any = [];
	public is_use_territory_for_commission_contract: boolean;
	public territoryModel: any = new TerritoryDxDropDownBoxModel();
	public selectedTerritoryData: any = [];
	@ViewChild(TerritoryDxDropDownBoxComponent) childTerritoryComponent: TerritoryDxDropDownBoxComponent;
	public ErpTypeValEnum = ErpTypeValEnum;
	public filterText: string = '';
	public decimalPointPercentageFormat: string = '1.0-0';
	public productSearchItemType: string;
	public commissionDetails: any = new CommissionContractModelUI();;
	public globalCurrencySymbol: string;
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();
	constructor(public _AppCommonSrvc: AppCommonSrvc,
		private _PermissionsService: NgxPermissionsService,
		private _DataService: DataService,
		private _ToastrService: ToastrService,
		private _RestApiService: RestApiService,
		private _LoginService: LoginService,
		//private _LoaderService: LoaderService,
		private _CommissionService: CommissionService,
		private _CurrencyPipe: CurrencyPipe,
		private _DecimalPipe: DecimalPipe) {
		this.exclusionGridCustomizeColumns = this.exclusionGridCustomizeColumns.bind(this);
		this.doCustomizeTextTierMinMax = this.doCustomizeTextTierMinMax.bind(this);
		this.doDisplayExprCustomerGroupText = this.doDisplayExprCustomerGroupText.bind(this);
		this.doDisplayExprItemsText = this.doDisplayExprItemsText.bind(this);
		this.doDisplayExprClassesText = this.doDisplayExprClassesText.bind(this);
	}

	ngOnInit() {
		this.customCurrencyOption = this._CommissionService.getCustomCurrencyOption(this.commissionDetails);
		this.decimalPointPercentageFormat = this._LoginService.decimalPointPercentageFormat;
		// if (this.exclusionTabList && this.exclusionTabList.length > 0) {
		// 	this.dataSourceExclusionTab = this.exclusionTabList;
		// }
		// get current user Erp type
		if (this._LoginService.loginUser && this._LoginService.loginUser.account_detail && this._LoginService.loginUser.account_detail.app_settings) {
			this.erp_type = this._LoginService.loginUser.account_detail.app_settings.erp_type;
			this.is_use_territory_for_commission_contract = this._LoginService.loginUser.account_detail.app_settings.is_use_territory_for_commission_contract || false;
			//this.is_use_territory_for_commission_contract = true;
			this.isCustomerAgeTiers = this._LoginService.loginUser.account_detail.app_settings.customer_age_tiers
			this.enable_profit_on_document_header = this._LoginService.loginUser.account_detail.enable_profit_on_document_header || false;
		}

		// get current user Erp type
		try {
			this.erp_type = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		} catch (e) {
			this.erp_type = '';
		}
		// check Is QB User (no need to view code of QB user)
		if (this._LoginService.loginUser.account_detail.app_settings.erp_type) {
			const erpType = this._LoginService.loginUser.account_detail.app_settings.erp_type;
			this.enable_classification_for_commision = this._LoginService.loginUser.account_detail.enable_classification_for_commision || false;
			this.enable_invoice_custom_fields_feature = this._LoginService.loginUser.account_detail.enable_invoice_custom_fields_feature || false;
			if (erpType === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
				this.isQBOnlineErpUser = true;
				this.customerGridBoxColumns = [
					{ dataField: 'code', caption: 'Code' },
					{ dataField: 'name', caption: 'Name' }
				];
				this.itemGridBoxColumns = [
					{ dataField: 'itemsku', caption: 'SKU', width: 100 },
					{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
					{ dataField: 'itemname', caption: 'Item', width: 100 },
					{ dataField: 'description', caption: 'Description' }
				];
			} else if (erpType === ErpTypeValEnum.QUICKBOOKS) {
				this.isQBErpUser = true;
				this.customerGridBoxColumns = [
					{ dataField: 'code', caption: 'Code', visible: false },
					{ dataField: 'name', caption: 'Name' },
				];
				this.itemGridBoxColumns = [
					{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
					{ dataField: 'itemname', caption: 'Item', width: 100 },
					{ dataField: 'description', caption: 'Description' }
				];
			} else {
				this.customerGridBoxColumns = [
					{ dataField: 'code', caption: 'Code' },
					{ dataField: 'name', caption: 'Name' }
				];
				this.itemGridBoxColumns = [
					{ dataField: 'erp_primary_key', caption: 'Key', visible: false },
					{ dataField: 'itemname', caption: 'Item', width: 100 },
					{ dataField: 'description', caption: 'Description' }
				];
			}

			if (this.enable_classification_for_commision) {
				this.isNetsuiteErpUser = true;
				this.classesGridBoxColumns = [
					{ dataField: 'guid', caption: 'Guid', visible: false },
					{ dataField: 'id', caption: 'Id', visible: false },
					{ dataField: 'name', caption: 'Classes' }
				];
				// this.customFieldBoxColumns = [
				// 	'clientname', 'itemsku', 'itemname', 'classname', 'description', 'tier_basis', 'commission_basis', 'selectedUom', 'rate'
				// ];
			}
			// this.customerGroupGridBoxColumns = [
			// 	{ dataField: 'guid', caption: 'Guid', visible: false },
			// 	{ dataField: 'group_name', caption: 'Name' },
			// 	{ dataField: 'user_list', caption: 'List', groupIndex: "0" },
			// ];
		}

		// get global currency
		this.globalCurrency = this._LoginService.globalCurrency;
		this.globalCurrencySymbol = this._CommissionService.globalCurrencySymbol;
		// Check Edit Mode
		const editCommissionGuid = this._CommissionService.getEditCommissionGuid();

		// Get current permissions
		const permissions = this._PermissionsService.getPermissions();
		const permsn = [];
		for (const per in permissions) {
			permsn.push(per);
		}
		if (permsn.indexOf('INCENTIVE_COMMISSION_MODIFY') === -1) {
			this.isDisablePermission = true;
			this.isCurrentTabPermission = 'INCENTIVE_COMMISSION_MODIFY';
		}
		// End current permissions
		this.editCommissionGuid = this._CommissionService.getEditCommissionGuid();
		this.GroupsList = this._DataService.getGroups();
		this.clearExclusionAgreementTabDetails();
		if (this.enable_classification_for_commision) {
			this.listOfTierBasis = this._CommissionService.getTierBasisForClasses();
			this.listOfCommissionBasis = this._CommissionService.getCommissionBasisForClasses();
		} else if (this.enable_profit_on_document_header) {
			this.listOfTierBasis = this._CommissionService.getTierBasisForProfitOnHeader();
			this.listOfCommissionBasis = this._CommissionService.getCommissionBasisForProfitOnHeader();
		} else {
			this.listOfTierBasis = this._CommissionService.getTierBasis();
			this.listOfCommissionBasis = this._CommissionService.getCommissionBasis();
		}
		if (this.erp_type === ErpTypeValEnum.NETSUITE) {
			if (this.enable_invoice_custom_fields_feature || this.enable_profit_on_document_header) {
				this.doGetFeatureCustomFields();
			} else {
				this.isViewExlusionTabGrid = true;
			}
		} else {
			this.isViewExlusionTabGrid = true;
		}
		this.getCustomerData();
		this.fetchListOfUoms();
		this.fetchListOfUomGroups();
		this.loadCustomerDataList();
		this.loadCustomerGroupDataList();
		if (this.enable_classification_for_commision) {
			this.loadClassesDataList();
		} else {
			this.loadItemDataList();
		}

	}

	ngOnChanges(_SimpleChanges: SimpleChanges) {
		if (_SimpleChanges['commissionDetailsProps']) {
			const commissionDetailsProps = _SimpleChanges['commissionDetailsProps'].currentValue;
			this.commissionDetails = commissionDetailsProps;
		}
		if (_SimpleChanges['exclusionTabList']) {
			const exclusionTabListProps = _SimpleChanges['exclusionTabList'].currentValue;
			this.dataSourceExclusionTab = exclusionTabListProps || [];
		}
	}

	ngOnDestroy(): void {
		if (this.getFeatureCustomFieldsLstSbn) {
			this.getFeatureCustomFieldsLstSbn.unsubscribe();
		}
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
	}
	// #region tof Fetch List of Uom(s)

	// Method used to get Uom List
	private fetchListOfUoms() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.UOM);
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name']));
		formData.append('is_dropdown', 'true');
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		this.getUomLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
						this.listOfUoms = response.data;
					}
				}
			}, error: (error) => {
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to get Uo Group List
	private fetchListOfUomGroups() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.UOMGROUPS);
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name', 'baseuom', 'uomgroupdefinitioncollection']));
		formData.append('is_dropdown', 'true');

		//this._LoaderService.show();
		if (this.getUomGroupLstSbsn) {
			this.getUomGroupLstSbsn.unsubscribe();
		}
		this.getUomGroupLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				//	this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUomGroups = response.data;
					}
				}
			}, error: (error) => {
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// #endregion

	//#region for Customer select box
	loadCustomerDataList() {
		// const viewFields = ['guid', 'code', 'name'];
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.CLIENTS,
			//view_fields: JSON.stringify(viewFields)
		};
		this.customerDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: 'name',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	public doDisplayExprCustomerGroupText(event: any) {
		let listOfTexts: any[] = [];
		if (this.customerOrGroupTab == 'customers') {
			listOfTexts = [];
			if (this.chkAllCustomer) {
				listOfTexts.push('All Customers');
			}
			if (this.chkAssignedCustomer) {
				listOfTexts.push('Assigned Customer(s)');
			}
			if (this.chkByTransaction) {
				listOfTexts.push('By Transaction');
			}
			if (this.chkSalesTeamTransaction) {
				listOfTexts.push('Sales Team Transaction');
			}
			if (this.arrSelectedCustomerList && this.arrSelectedCustomerList.length > 0) {
				listOfTexts.push(this.arrSelectedCustomerList.length + ' Customer(s)');
			}
		}
		if (this.customerOrGroupTab === 'groups') {
			listOfTexts = [];
			if (this.chkAllCustomrGroup) {
				listOfTexts.push('All Groups');
			}
			if (this.arrSelectedCustomerGroupList.length > 0) {
				listOfTexts.push(this.arrSelectedCustomerGroupList.length + ' Group(s)');
			}
		}
		return listOfTexts.length > 0 ? listOfTexts.join(', ') : '';
	}

	onCustomerValueChanged(e: any) {
		if (e.value === null) {
			this.arrSelectedCustomerList = [];
			this.arrSelectedCustomerGroupList = [];
			this.customerGridBoxValue = [];
			if (this.customerDataGrid) {
				this.customerDataGrid.instance.refresh();
			}
			this.chkMyCustomers = false;
			this.chkAllCustomer = false;
			this.chkAssignedCustomer = false;
			this.chkByTransaction = false;
			this.chkSalesTeamTransaction = false;
			this.chkAllCustomrGroup = false;
			this.lengthSelectedCustomers = '';
			this.lengthSelectedCustomersAndGroups = '';
		}
	}
	checkAllCustomer() {
		if (this.chkAllCustomer) {
			this.chkMyCustomers = false;
		}
		this.setCustomersAndGroupsText();
		// if (this.chkAllCustomer) {
		// 	this.lengthSelectedCustomers = 'All Customers Selected';
		// 	this.arrSelectedCustomerList = [];
		// 	this.customerDataGrid.instance.refresh();
		// } else {
		// 	this.lengthSelectedCustomers = '';
		// }
	}
	checkAssignedCustomer() {
		if (this.chkAssignedCustomer) {
			this.chkByTransaction = false;
			// this.chkSalesTeamTransaction = false;
		}
		this.setCustomersAndGroupsText();
	}
	checkByTransaction() {
		if (this.chkByTransaction) {
			this.chkAssignedCustomer = false;
			// this.chkSalesTeamTransaction = false;
		}
		this.setCustomersAndGroupsText();
	}
	checkSalesTeantransaction() {
		// if (this.chkSalesTeamTransaction) {
		// 	this.chkByTransaction = false;
		// 	this.chkAssignedCustomer = false;
		// }
		this.setCustomersAndGroupsText();
	}
	onCustomerSelectionChanged(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			// this.chkAllCustomer = false;
			this.lengthSelectedCustomers = e.selectedRowsData.length + ' Customer(s) Selected';
			this.customerGridBoxValue = [];
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.customerGridBoxValue.push(e.selectedRowsData[i].code);
			};
		}
		if (e.selectedRowsData && e.selectedRowsData.length === 0 && !this.chkAllCustomer) {
			this.lengthSelectedCustomers = '';
		}
		this.setCustomersAndGroupsText();
	}

	//#endregion

	//#region for Item|Product select box
	loadItemDataList() {
		// const viewFields = ['erp_primary_key', 'itemsku', 'itemname', 'description'];
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.ITEMS,
			//view_fields: JSON.stringify(viewFields)
		};
		this.itemDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: 'itemname',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	public doDisplayExprItemsText(event: any) {
		let listOfTexts: any[] = [];
		if (this.chkAllItem) {
			listOfTexts.push('All Items');
		}
		if (this.arrSelectedItemList && this.arrSelectedItemList.length > 0) {
			listOfTexts.push(this.arrSelectedItemList.length + ' Item(s)');
		}
		return listOfTexts.length > 0 ? listOfTexts.join(', ') : '';
	}

	onItemValueChanged(e: any) {
		if (e.value === null) {
			this.arrSelectedItemList = [];
			this.itemGridBoxValue = [];
			this.itemDataGrid.instance.refresh();
			this.chkAllItem = false;
			this.lengthSelectedItems = '';
		}
	}
	checkAllItem() {
		// if (this.chkAllItem) {
		// 	this.lengthSelectedItems = 'All Items Selected';
		// 	this.arrSelectedItemList = [];
		// 	this.itemDataGrid.instance.refresh();
		// } else {
		// 	this.lengthSelectedItems = '';
		// }
		// if (this.chkAllItem) {
		this.setItemSelctedText();
	}
	onItemSelectionChanged(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			// this.chkAllItem = false;
			//this.lengthSelectedItems = e.selectedRowsData.length + ' Items Selected';
			this.itemGridBoxValue = [];
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.itemGridBoxValue.push(e.selectedRowsData[i].erp_primary_key);
			};
		}
		// if (e.selectedRowsData && e.selectedRowsData.length === 0 && !this.chkAllItem) {
		// 	this.lengthSelectedItems = '';
		// }
		this.setItemSelctedText();
	}
	setItemSelctedText() {
		let lengthSelectedItems = '';
		this.lengthSelectedItems = '';
		if (this.arrSelectedItemList && this.arrSelectedItemList.length > 0) {
			lengthSelectedItems = this.arrSelectedItemList.length + ' Item(s) Selected';
		}

		if (this.chkAllItem) {
			lengthSelectedItems = 'All Item ' + (lengthSelectedItems ? ', ' : '') + (lengthSelectedItems || '');
		}
		this.lengthSelectedItems = lengthSelectedItems;
	}
	//#endregion

	//#region for Classes select box |  only show  while ERP type NETSUITE
	loadClassesDataList() {
		// const viewFields = ['erp_primary_key', 'classesku', 'classname', 'description'];
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.IX_CLASSIFICATIONS,
			//view_fields: JSON.stringify(viewFields)
		};
		this.classesDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: 'name',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	public doDisplayExprClassesText(event: any) {
		let listOfText: any[] = [];
		if (this.chkAllClasses) {
			listOfText.push('All Classes');
		}
		if (this.arrSelectedClassesList.length > 0) {
			listOfText.push(this.arrSelectedClassesList.length + ' Classes');
		}
		return listOfText.length > 0 ? listOfText.join(', ') : '';
	}

	onClassesValueChanged(e: any) {
		if (e.value === null) {
			this.arrSelectedClassesList = [];
			this.classesGridBoxValue = [];
			this.classesDataGrid.instance.refresh();
			this.chkAllClasses = false;
			this.lengthSelectedClasses = '';
		}
	}
	checkAllClasses() {
		// if (this.chkAllClasses) {
		// 	this.lengthSelectedClasses = 'All Classes Selected';
		// 	this.arrSelectedClassesList = [];
		// 	this.classesDataGrid.instance.refresh();
		// } else {
		// 	this.lengthSelectedClasses = '';
		// }
		// if (this.chkAllClasses) {
		this.setClassesSelctedText();
	}
	onClassesSelectionChanged(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			// this.chkAllClasses = false;
			//this.lengthSelectedClasses = e.selectedRowsData.length + ' Classes Selected';
			this.classesGridBoxValue = [];
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.classesGridBoxValue.push(e.selectedRowsData[i].id);
			};
		}
		// if (e.selectedRowsData && e.selectedRowsData.length === 0 && !this.chkAllClasses) {
		// 	this.lengthSelectedClasses = '';
		// }
		this.setClassesSelctedText();
	}
	setClassesSelctedText() {
		let lengthSelectedClasses = '';
		this.lengthSelectedClasses = '';
		if (this.arrSelectedClassesList && this.arrSelectedClassesList.length > 0) {
			lengthSelectedClasses = this.arrSelectedClassesList.length + ' Classes Selected';
		}

		if (this.chkAllClasses) {
			lengthSelectedClasses = 'All Classes ' + (lengthSelectedClasses ? ', ' : '') + (lengthSelectedClasses || '');
		}
		this.lengthSelectedClasses = lengthSelectedClasses;
	}
	//#endregion

	// #region customer Groups select box

	public clickCustomersOrGroupTab(event, customerOrGroupTab: string) {
		this.customerOrGroupTab = customerOrGroupTab;
		if (this.customerDataGrid) {
			this.selectedCustomerData = this.customerDataGrid.instance.getSelectedRowsData();
		}
		if (this.customerGroupDataGrid) {
			this.selectedCustomerGroupData = this.customerGroupDataGrid.instance.getSelectedRowsData();
		}
	}

	public checkMyCustomers() {
		if (this.chkMyCustomers) {
			this.chkAllCustomer = false;
		}
		this.setCustomersAndGroupsText();
	}

	loadCustomerGroupDataList() {
		// const viewFields = ['guid', 'code', 'name'];
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.USERGROUPS,
			//view_fields: JSON.stringify(viewFields)
		};
		this.groupDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: 'group_name',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	checkAllCustomerGroup() {
		// if (this.chkAllCustomer) {
		// 	this.lengthSelectedCustomers = 'All Customers Selected';
		// 	this.arrSelectedCustomerList = [];
		// 	this.customerDataGrid.instance.refresh();
		// } else {
		// 	this.lengthSelectedCustomers = '';
		// }
	}

	onCustomerGroupSelectionChanged(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			this.chkAllCustomer = false;
			this.lengthSelectedGroups = e.selectedRowsData.length + ' Group(s) Selected';
			this.customerGroupGridBoxValue = [];
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.customerGroupGridBoxValue.push(e.selectedRowsData[i].code);
			};
		}
		if (e.selectedRowsData && e.selectedRowsData.length === 0 && !this.chkAllCustomrGroup) {
			this.lengthSelectedGroups = '';
		}
		this.setCustomersAndGroupsText();
	}

	setCustomersAndGroupsText() {
		let lengthSelectedCustomersAndGroups: string = '';
		if (this.lengthSelectedCustomers) {
			lengthSelectedCustomersAndGroups = this.lengthSelectedCustomers;
		}
		if (this.lengthSelectedGroups) {
			lengthSelectedCustomersAndGroups = this.lengthSelectedGroups;
		}
		if (this.lengthSelectedGroups && this.lengthSelectedCustomers) {
			lengthSelectedCustomersAndGroups = this.lengthSelectedCustomers + ' And ' + this.lengthSelectedGroups;
		}

		if (this.chkAllCustomer) {
			lengthSelectedCustomersAndGroups = 'All Customer ' + (lengthSelectedCustomersAndGroups ? ', ' : '') + (lengthSelectedCustomersAndGroups || '');
		}

		if (this.chkAssignedCustomer) {
			lengthSelectedCustomersAndGroups = 'Assigned Customer(s) ' + (lengthSelectedCustomersAndGroups ? ', ' : '') + (lengthSelectedCustomersAndGroups || '');
		}

		if (this.chkByTransaction) {
			lengthSelectedCustomersAndGroups = 'By Transactions ' + (lengthSelectedCustomersAndGroups ? ', ' : '') + (lengthSelectedCustomersAndGroups || '');
		}

		if (this.chkSalesTeamTransaction) {
			lengthSelectedCustomersAndGroups = 'Sales Team Transactions ' + (lengthSelectedCustomersAndGroups ? ', ' : '') + (lengthSelectedCustomersAndGroups || '');
		}

		if (this.chkMyCustomers) {
			lengthSelectedCustomersAndGroups = 'My Customer ' + (lengthSelectedCustomersAndGroups ? ', ' : '') + (lengthSelectedCustomersAndGroups || '');
		}

		if (this.chkAllCustomrGroup) {
			lengthSelectedCustomersAndGroups = 'All Group ' + (lengthSelectedCustomersAndGroups ? ', ' : '') + (lengthSelectedCustomersAndGroups || '');
		}
		this.lengthSelectedCustomersAndGroups = lengthSelectedCustomersAndGroups;
	}

	checkAllGroup() {
		this.setCustomersAndGroupsText();
	}

	//#endregion

	// #region for Items And Groups Datagrid Methods
	public getUserListByGroupDetails(element) {
		return element.data.user_detail_list || [];
	}
	//#endregion

	public clickExclusionsGroupRadioButton(tab) {
		this.selectProductSearchTabData = [];
		this.isProductSearchTabAllSelected = false;
		this.exclusionTabModel.productname = '';
		if (tab === 'addgroupexclusion') {
			this.selectProductSearchTabData = JSON.parse(JSON.stringify(this.GroupsList));
		}
		this.selectProductSearchTabData.forEach(element => {
			element.selected = false;
		});
	}

	public clearExclusionAgreementTabDetails() {
		this.exclusionsGroupRadioButton = 'addproductexclusion';
		this.arrSelectedCustomerList = [];
		this.arrSelectedClassesList = [];
		this.arrSelectedCustomerGroupList = [];
		this.lengthSelectedCustomersAndGroups = '';
		this.lengthSelectedCustomers = '';
		this.lengthSelectedGroups = '';
		this.lengthSelectedItems = '';
		this.lengthSelectedClasses = '';
		this.customerGridBoxValue = [];
		this.chkAllCustomer = false;
		this.chkAssignedCustomer = false;
		this.chkByTransaction = false;
		this.chkSalesTeamTransaction = false;
		if (this.customerDataGrid) {
			this.customerDataGrid.instance.refresh();
			this.customerDataGrid.instance.clearFilter();
		}
		if (this.customerGroupDataGrid) {
			this.customerGroupDataGrid.instance.refresh();
			this.customerGroupDataGrid.instance.clearFilter();
		}
		// if (this.customerDropDown) {
		// 	this.customerDropDown.instance.reset();
		// }
		this.arrSelectedItemList = [];
		this.itemGridBoxValue = [];
		this.chkAllItem = false;
		this.chkAllClasses = false;
		if (this.itemDataGrid) {
			this.itemDataGrid.instance.refresh();
			this.itemDataGrid.instance.clearFilter();
		}
		if (this.classesDataGrid) {
			this.classesDataGrid.instance.refresh();
			this.classesDataGrid.instance.clearFilter();
		}
		this.chkAllCustomrGroup = false;
		this.chkMyCustomers = false;
		if (this.childTerritoryComponent) {
			this.childTerritoryComponent.clearTerritorySelectList();
		}
	}
	public doOnEditorPreparingExclusionTab(event) {
		// if (event && event['row'] && event['row']['data']) {
		// 	return true;
		// } else {
		// 	return false;
		// }
		if (event && event['row']) {
			// if (event.dataField === 'rate') {
			// 	if (event.row.isEditing) {
			// 		const exclusionTabListCloneArr: any = [];
			// 		if (event.component.getVisibleRows().length > 0) {
			// 			event.component.getVisibleRows().forEach(element => {
			// 				exclusionTabListCloneArr.push(element.data);
			// 			});
			// 		}
			// 		this.exclusionTabListClone = JSON.parse(JSON.stringify(exclusionTabListCloneArr));
			// 	}
			// }
			const itemObject = event.row.data || [];
			if (event.dataField === 'selectedUom') {
				event.editorOptions.visible = false;
				event.editorOptions.disabled = true;
				let uomDataSource = [];
				event.editorOptions.dataSource = uomDataSource;
				if (!itemObject.allproducts) {
					if (itemObject.commission_basis === 'dollarperuom') {
						event.editorOptions.visible = true;
						event.editorOptions.disabled = false;
						uomDataSource = itemObject.uomlist;
						event.editorOptions.dataSource = uomDataSource;
					} else {
						event.editorOptions.visible = false;
						event.editorOptions.disabled = true;
						event.editorOptions.dataSource = [];
					}
				}
			}

			if (event.dataField === 'tier_basis') {
				let listOfTierBasis = this.listOfTierBasis;
				event.editorOptions.dataSource = listOfTierBasis;
			}

			if (event.dataField === 'commission_basis') {
				let listOfCommissionBasis = this.listOfCommissionBasis;
				if (itemObject.allproducts) {
					listOfCommissionBasis = this.listOfCommissionBasis.filter(rb => rb.code === 'dollar' || rb.code === 'percentage');
				}

				if (itemObject['itemType']) {
					if (itemObject['itemType'] === 'OtherCharge' || itemObject['itemType'] === 'Service') {
						listOfCommissionBasis = this.listOfCommissionBasis.filter(rb => rb.code === 'dollar' || rb.code === 'percentage');
					}
				}
				event.editorOptions.dataSource = listOfCommissionBasis;
			}

			if (event.dataField === 'rate') {
				event.editorOptions.visible = false;
				if (itemObject.allproducts) {
					if (itemObject.tier_basis === 'notiers') {
						event.editorOptions.visible = true;
					}
				} else {
					if (itemObject.tier_basis === 'notiers') {
						event.editorOptions.visible = true;
					}
				}
			}

		}
	}

	public doOnRowUpdatedExclusionTab(event) {

		if (event.data.commission_basis === 'percentage' || event.data.commission_basis === 'dollar') {
			event.data.selectedUom = '';
		}
		if (event.data.tier_basis === 'notiers') {
			event.data.tiers = [];
		}
		if (event.data.tier_basis !== 'notiers') {
			event.data.rate = null;
		}

		// if (this.checkExclusionListDuplicateRecords(event.data)) {
		// 	this._ToastrService.info('Reacord already exists !', 'Info', { closeButton: true, tapToDismiss: true });
		// 	return false;
		// } else {
		// 	this.calSaveCommissionContract();
		// }
	}

	public doOnRowRemovedExclusionTab(event) {
		this.calSaveCommissionContract();
	}

	public doOnonRowPreparedExclusionTab(event) {
		if (event.rowType === "data" && event.data.tier_basis === 'notiers') {
			if (event.rowElement.querySelector(".dx-command-expand")) {
				event.rowElement.querySelector(".dx-command-expand").firstChild.classList.remove("dx-datagrid-group-closed");
				event.rowElement.querySelector(".dx-command-expand").classList.remove("dx-datagrid-expand");
				const isExpanded = event.component.isRowExpanded(event.key);
				setTimeout(() => {
					if (isExpanded) {
						event.component.collapseRow(event.key);
					}
				}, 20);
			}


		}
	}

	public doOnRowValidatingExclusionTab(event) {
		if (event['newData']) {
			let itemObject = JSON.parse(JSON.stringify(event['oldData']));
			let dataFiledsList = Object.keys(event['newData']);
			dataFiledsList.forEach(element => {
				itemObject[element] = event['newData'][element];
			});
			// this.exclusionTabListClone = JSON.parse(JSON.stringify(this.exclusionTabList));
			// const indexOfRow = this.exclusionTabListClone.findIndex(i => i.guid === event.key);
			// this.exclusionTabListClone[indexOfRow] = event['newData'];
			if (this.erp_type === ErpTypeValEnum.NETSUITE) {
				if (this.checkExclusionListDuplicateRecords(itemObject)) {
					this._ToastrService.info('Duplicate entry: an entry matching this customer, product, and custom fields already exists', 'Info', { closeButton: true, tapToDismiss: true });
					const indexOfOldRow = this.exclusionTabList.findIndex(i => i.guid === event.key);
					if (indexOfOldRow !== -1) {
						dataFiledsList.forEach(element => {
							event['newData'][element] = this.exclusionTabList[indexOfOldRow][element];
						});

					}
				} else {
					this.calSaveCommissionContract();
				}
			} else {
				this.calSaveCommissionContract();
			}
		}
	}

	public doOnInitNewRowTiersItmTab(event, element) {
		const itemObject = element.data;
		event.data = {
			guid: null,
			mintier: null,
			maxtier: null,
			rate: null,
			items: itemObject.items.map((item) => {
				let listOfSpecificUoms = [];
				if (!item.allproducts) {
					const priceListNum = itemObject.pricelistnum || '';
					listOfSpecificUoms = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, item, priceListNum);
				}
				let defaultSelectedUom = '';
				let defaultListPrice = 0;
				if (listOfSpecificUoms.length > 0) {
					defaultSelectedUom = listOfSpecificUoms[0].code || '';
					defaultListPrice = listOfSpecificUoms[0].price || 0;
				}
				return {
					allproducts: item.allproducts,
					allcustomergroups: item.allcustomergroups,
					mycustomers: item.mycustomers,
					erp_primary_key: item.erp_primary_key,
					itemType: item.itemType,
					itemname: item.itemname,
					itemsku: item.itemsku,
					description: item.description,
					selectedUom: !item.allproducts && itemObject.commission_basis === 'dollarperuom' ? defaultSelectedUom : '',
					rate: null,
					listPrice: +defaultListPrice.toFixed(2),
					commissionfinalprice: defaultListPrice,
				}
			})
		};
		event.data.guid = this.guid.newGuid();
		if (itemObject.tiers && itemObject.tiers.length > 0) {
			// const mintier = itemObject.tiers[itemObject.tiers.length - 1].maxtier !== '-' ? parseFloat(itemObject.tiers[itemObject.tiers.length - 1].maxtier) + 1 : parseFloat(itemObject.tiers[itemObject.tiers.length - 1].mintier) + 1;
			if (itemObject.tier_basis === 'volume' || itemObject.tier_basis === 'revenue') {
				if (itemObject.commission_basis === 'percentage') {
					// event.data.mintier = mintier;
				}
				if (itemObject.commission_basis === 'dollar') {
					// event.data.mintier = mintier;
				}
			}
			// event.data.maxtier = '-';
		} else {
			if (itemObject.tier_basis === 'volume') {
				if (itemObject.commission_basis === 'percentage') {
					// event.data.mintier = 1;
				}
				if (itemObject.commission_basis === 'dollar') {
					// event.data.mintier = 0.01;
				}
			}
		}
	}

	public doOnRowInsertedTiersItmTab(event, element) {
		// const itemObject = event.row.data;
		//this.saveCommissionExclusionEvent.emit('save');
	}

	public doOnEditorPreparingTiersItmTab(event, element) {
	}

	public doOnRowUpdatedTiersItmTab(event, element) {
	}

	public doOnRowRemovedTiersItmTab(event, element) {
		this.calSaveCommissionContract();
	}

	public doOnSavedTiersItmTab(event, element) {
		this.doConvertDecimalPointByConfiguration();
		if (this.tiersItemDetailssDataGridRef) {
			this.tiersItemDetailssDataGridRef.instance.refresh();
		}
		this.calSaveCommissionContract();
	}

	public doOnSavedExclusionTab() {
		this.doConvertDecimalPointByConfiguration();
		if (this.exclusionTabDataGridRef) {
			this.exclusionTabDataGridRef.instance.refresh();
		}
		if (this.tiersItemDetailssDataGridRef) {
			this.tiersItemDetailssDataGridRef.instance.refresh();
		}
		//this.saveCommissionExclusionEvent.emit('save');
	}

	public getTiersByItemDetails(element) {
		return element.data.tiers || [];
	}

	public getCustomerAgeTiersByItemDetails(element) {
		return element.data || [];
	}

	public setCellValueTierBasisColumn(rowData, value) {
		const column = (<any>this);
		column.defaultSetCellValue(rowData, value);
	}

	public setCellValueCommissionBasisColumn(rowData, value) {
		const column = (<any>this);
		column.defaultSetCellValue(rowData, value);
	}

	public setCellValueUomColumn(rowData, value) {
		const column = (<any>this);
		column.defaultSetCellValue(rowData, value);
	}

	public setCellValueRateColumn(rowData, value) {
		const column = (<any>this);
		column.defaultSetCellValue(rowData, value);
	}

	public addExclusionAgreementTabDetails() {
		this.selectedProductData = [];
		if (this.itemDataGrid) {
			this.selectedProductData = [];
			this.selectedProductData = this.itemDataGrid.instance.getSelectedRowsData();
		}
		if (this.customerDataGrid) {
			this.selectedCustomerData = [];
			this.selectedCustomerData = this.customerDataGrid.instance.getSelectedRowsData();
		}
		if (this.customerGroupDataGrid) {
			this.selectedCustomerGroupData = [];
			this.selectedCustomerGroupData = this.customerGroupDataGrid.instance.getSelectedRowsData();
		}
		//Territory drop-down-list
		if (this.childTerritoryComponent && this.childTerritoryComponent.territoryDataGrid) {
			this.selectedTerritoryData = this.childTerritoryComponent.territoryDataGrid.instance.getSelectedRowsData();
		}
		if (this.territoryModel.chkAllTerritory) {
			this.allTerritoryChecked();
		}
		//End Territory drop-down-list
		if (this.classesDataSource) {
			this.selectedClassesData = [];
			this.selectedClassesData = this.classesDataGrid.instance.getSelectedRowsData();
		}
		// const isExistItemIndex = this.exclusionTabList.findIndex(itemEle => itemEle.clientcode === this.exclusionTabModel.clientcode && itemEle.itemcode === this.exclusionTabModel.clientcode);
		if (this.chkAllClasses) {
			this.allClassesChecked();
		}
		if (this.chkAllItem) {
			this.allProductChecked();
		}
		if (this.chkAllCustomer || this.chkMyCustomers) {
			this.allCustomerChecked();
		}
		if (this.chkAssignedCustomer || this.chkByTransaction) {
			this.allAssignOrByTransactionCustomerChk();
		}
		if (this.chkSalesTeamTransaction) {
			this.allSalesTeamTransactionCustomerChk();
		}
		if (this.chkAllCustomrGroup) {
			this.allCustomerGroupChecked();
		}

		this.doAddExclusionToListTab();
		// this.saveCommissionExclusionEvent.emit('save');
	}

	public allCustomerGroupChecked() {
		const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
		exelusionTierbaseModel['code'] = '';
		exelusionTierbaseModel['group_name'] = 'All Groups';
		exelusionTierbaseModel['guid'] = '';
		exelusionTierbaseModel.itemOrGroup = 'item';
		exelusionTierbaseModel.itemsku = '';
		exelusionTierbaseModel.itemType = '';
		exelusionTierbaseModel.erp_primary_key = 'All Groups';
		exelusionTierbaseModel.itemname = '';
		exelusionTierbaseModel.description = '';
		exelusionTierbaseModel.rate = null;
		exelusionTierbaseModel.allcustomergroups = true;
		exelusionTierbaseModel.mycustomers = this.chkMyCustomers;
		this.selectedCustomerGroupData.unshift(JSON.parse(JSON.stringify(exelusionTierbaseModel)));
	}

	public allTerritoryChecked() {
		const exelusionTierbaseModel = new ExclusionTierbaseTabModel();

		exelusionTierbaseModel['code'] = '';
		exelusionTierbaseModel['territory_name'] = "All Territories";
		exelusionTierbaseModel['guid'] = '';
		exelusionTierbaseModel.itemOrGroup = 'group';
		exelusionTierbaseModel.itemsku = '';
		exelusionTierbaseModel.itemType = '';
		exelusionTierbaseModel.erp_primary_key = "All Territories";
		exelusionTierbaseModel.itemname = '';
		exelusionTierbaseModel.description = '';
		exelusionTierbaseModel.rate = null;
		exelusionTierbaseModel.allterritories = this.territoryModel.chkAllTerritory;
		this.selectedTerritoryData.unshift(JSON.parse(JSON.stringify(exelusionTierbaseModel)));
	}
	public allCustomerChecked() {
		const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
		const textCustomerGroup = this.chkAllCustomer ? 'All Customers' : 'My Customers'
		exelusionTierbaseModel['code'] = '';
		exelusionTierbaseModel['name'] = textCustomerGroup;
		exelusionTierbaseModel['guid'] = '';
		exelusionTierbaseModel.itemOrGroup = 'group';
		exelusionTierbaseModel.itemsku = '';
		exelusionTierbaseModel.itemType = '';
		exelusionTierbaseModel.erp_primary_key = textCustomerGroup;
		exelusionTierbaseModel.itemname = '';
		exelusionTierbaseModel.description = '';
		exelusionTierbaseModel.rate = null;
		exelusionTierbaseModel.allcustomers = this.chkAllCustomer;
		exelusionTierbaseModel.mycustomers = this.chkMyCustomers;
		this.selectedCustomerData.unshift(JSON.parse(JSON.stringify(exelusionTierbaseModel)));
	}

	public allClassesChecked() {
		let itemname = 'All Classes';

		const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
		exelusionTierbaseModel['code'] = '';
		exelusionTierbaseModel['name'] = '';
		exelusionTierbaseModel['guid'] = '';
		exelusionTierbaseModel.classguid = 'allclasses';
		exelusionTierbaseModel.classid = itemname;
		exelusionTierbaseModel.classname = itemname;
		exelusionTierbaseModel.allclasses = true;

		this.selectedClassesData.unshift(JSON.parse(JSON.stringify(exelusionTierbaseModel)));
	}

	public allAssignOrByTransactionCustomerChk() {
		const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
		const textVal = this.chkAssignedCustomer ? 'Assigned Customer(s)' : 'By Transactions';
		exelusionTierbaseModel['code'] = '';
		exelusionTierbaseModel['name'] = textVal;
		exelusionTierbaseModel['guid'] = '';
		exelusionTierbaseModel.itemOrGroup = 'group';
		exelusionTierbaseModel.itemsku = '';
		exelusionTierbaseModel.itemType = '';
		exelusionTierbaseModel.erp_primary_key = textVal;
		exelusionTierbaseModel.itemname = '';
		exelusionTierbaseModel.description = '';
		exelusionTierbaseModel.rate = null;
		exelusionTierbaseModel.allcustomers = this.chkAllCustomer;
		exelusionTierbaseModel.mycustomers = this.chkMyCustomers;
		exelusionTierbaseModel.assignedcustomers = this.chkAssignedCustomer;
		exelusionTierbaseModel.bytransactions = this.chkByTransaction;
		exelusionTierbaseModel.sales_team_transactions = false;
		this.selectedCustomerData.unshift(JSON.parse(JSON.stringify(exelusionTierbaseModel)));
	}

	public allSalesTeamTransactionCustomerChk() {
		const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
		const textVal = 'Sales Team Transactions';
		exelusionTierbaseModel['code'] = '';
		exelusionTierbaseModel['name'] = textVal;
		exelusionTierbaseModel['guid'] = '';
		exelusionTierbaseModel.itemOrGroup = 'group';
		exelusionTierbaseModel.itemsku = '';
		exelusionTierbaseModel.itemType = '';
		exelusionTierbaseModel.erp_primary_key = textVal;
		exelusionTierbaseModel.itemname = '';
		exelusionTierbaseModel.description = '';
		exelusionTierbaseModel.rate = null;
		exelusionTierbaseModel.allcustomers = this.chkAllCustomer;
		exelusionTierbaseModel.mycustomers = this.chkMyCustomers;
		exelusionTierbaseModel.assignedcustomers = false;
		exelusionTierbaseModel.bytransactions = false;
		exelusionTierbaseModel.sales_team_transactions = this.chkSalesTeamTransaction;
		this.selectedCustomerData.unshift(JSON.parse(JSON.stringify(exelusionTierbaseModel)));
	}

	public allProductChecked() {
		let itemname = '';
		if (this.exclusionsGroupRadioButton === 'addproductexclusion') {
			itemname = 'All Items';
		}
		if (this.exclusionsGroupRadioButton === 'addgroupexclusion') {
			itemname = 'All Groups';
		}
		const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
		exelusionTierbaseModel['code'] = '';
		exelusionTierbaseModel['name'] = '';
		exelusionTierbaseModel['guid'] = '';
		exelusionTierbaseModel.itemOrGroup = 'item';
		exelusionTierbaseModel.itemsku = '';
		exelusionTierbaseModel.itemType = '';
		exelusionTierbaseModel.erp_primary_key = itemname;
		exelusionTierbaseModel.itemname = itemname;
		exelusionTierbaseModel.description = '';
		exelusionTierbaseModel.rate = null;
		exelusionTierbaseModel.allproducts = true;
		exelusionTierbaseModel.mycustomers = this.chkMyCustomers;

		this.selectedProductData.unshift(JSON.parse(JSON.stringify(exelusionTierbaseModel)));
	}

	public checkExclusionListDuplicateRecords(item: any) {
		let indexExcusion = -1;
		if (this.exclusionTabList && this.exclusionTabList.length > 0) {
			let indexExcusion;

			if (this.erp_type === ErpTypeValEnum.NETSUITE && (this.enable_invoice_custom_fields_feature || this.enable_profit_on_document_header)) {
				const isduplicated = this.checkValidateCustomFutureField(item);
				if (isduplicated) {
					indexExcusion = -1;
				} else {
					indexExcusion = 0;
				}
			} else {
				if (item.assignedcustomers || item.bytransactions) {
					indexExcusion = this.exclusionTabList.findIndex(i => (i.assignedcustomers === true || i.bytransactions === true) && i.sales_team_transactions == item.sales_team_transactions && i.itemname === item.itemname && i.guid !== item.guid);
				} else if (item.sales_team_transactions) {
					indexExcusion = this.exclusionTabList.findIndex(i => i.sales_team_transactions == true && i.itemname === item.itemname && i.guid !== item.guid);
				}
				else {
					if (this.enable_classification_for_commision) {
						indexExcusion = this.exclusionTabList.findIndex(i => i.clientname === item.clientname && i.classname === item.classname && i.guid !== item.guid && i.tier_basis === item.tier_basis && i.commission_basis === item.commission_basis);
					} else if (this.is_use_territory_for_commission_contract) {
						indexExcusion = this.exclusionTabList.findIndex(i => i.territoryname === item.territoryname && i.itemname === item.itemname && i.guid !== item.guid && i.tier_basis === item.tier_basis && i.commission_basis === item.commission_basis && i.selectedUom === item.selectedUom);
					}
					else {
						indexExcusion = this.exclusionTabList.findIndex(i => i.clientname === item.clientname && i.itemname === item.itemname && i.guid !== item.guid && i.tier_basis === item.tier_basis && i.commission_basis === item.commission_basis && i.selectedUom === item.selectedUom);
					}
				}
			}
			if (indexExcusion !== -1) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	public checkValidateCustomFutureField(item: any): boolean {
		if (this.listOfFeatureCustomFields.length > 0) {
			let returnVal: boolean = false;
			const custLineItem1: any = _.where(this.listOfFeatureCustomFields, { ix_custom_field: 'custom_lineitem_1' });
			const custLineItem2: any = _.where(this.listOfFeatureCustomFields, { ix_custom_field: "custom_lineitem_2" });
			const custHeader1: any = _.where(this.listOfFeatureCustomFields, { ix_custom_field: "custom_header_1" });
			const custHeader2: any = _.where(this.listOfFeatureCustomFields, { ix_custom_field: "custom_header_2" });
			//const fiteredCustomFieldData = _.where(this.listOfFeatureCustomFields, { display: true });
			// let filterCon: any = '';
			// for (let i = 0; i < fiteredCustomFieldData.length; i++) {
			// 	const element = fiteredCustomFieldData[i];
			// 	filterCon = filterCon + 'j.' + element.ix_custom_field + ' === item.' + element.ix_custom_field;
			// 	if (i < fiteredCustomFieldData.length - 1) {
			// 		filterCon = filterCon + ' && ';
			// 	}
			// 	let indexExcusion = -1;
			// 	if (item.assignedcustomers || item.bytransactions) {
			// 		indexExcusion = this.exclusionTabList.findIndex(j => (j.assignedcustomers === true || j.bytransactions == true) && j.itemname === item.itemname && j[element.ix_custom_field] === item[element.ix_custom_field]);
			// 	} else {
			// 		indexExcusion = this.exclusionTabList.findIndex(j => j.clientname === item.clientname && j.classname === item.classname && j[element.ix_custom_field] === item[element.ix_custom_field]);
			// 	}
			// 	if (indexExcusion === -1) {
			// 		returnVal = true;
			// 	}
			// }
			const itemIndex = this.exclusionTabList.findIndex(j => j.guid === item.guid);
			let newindexExcusion;
			if (this.enable_classification_for_commision) {
				newindexExcusion = this.exclusionTabList.findIndex(j => j.clientname === item.clientname &&
					j.classname === item.classname &&
					(custLineItem1.length && custLineItem1[0].display ? (item.custom_lineitem_1 === undefined ? (j.custom_lineitem_1 === undefined || j.custom_lineitem_1 === null || j.custom_lineitem_1 === '' || j.custom_lineitem_1 === false) : j.custom_lineitem_1 === item.custom_lineitem_1) : true) &&
					(custLineItem2.length && custLineItem2[0].display ? (item.custom_lineitem_2 === undefined ? (j.custom_lineitem_2 === undefined || j.custom_lineitem_2 === null || j.custom_lineitem_2 === '' || j.custom_lineitem_2 === false) : j.custom_lineitem_2 === item.custom_lineitem_2) : true) &&
					(custHeader1.length && custHeader1[0].display ? (item.custom_header_1 === undefined ? (j.custom_header_1 === undefined || j.custom_header_1 === null || j.custom_header_1 === '' || j.custom_header_1 === false) : j.custom_header_1 === item.custom_header_1) : true) &&
					(custHeader2.length && custHeader2[0].display ? (item.custom_header_2 === undefined ? (j.custom_header_2 === undefined || j.custom_header_2 === null || j.custom_header_2 === '' || j.custom_header_2 === false) : j.custom_header_2 === item.custom_header_2) : true));
			} else {
				newindexExcusion = this.exclusionTabList.findIndex(j => j.clientname === item.clientname &&
					j.itemname === item.itemname &&
					(custLineItem1.length && custLineItem1[0].display ? (item.custom_lineitem_1 === undefined ? (j.custom_lineitem_1 === undefined || j.custom_lineitem_1 === null || j.custom_lineitem_1 === '' || j.custom_lineitem_1 === false) : j.custom_lineitem_1 === item.custom_lineitem_1) : true) &&
					(custLineItem2.length && custLineItem2[0].display ? (item.custom_lineitem_2 === undefined ? (j.custom_lineitem_2 === undefined || j.custom_lineitem_2 === null || j.custom_lineitem_2 === '' || j.custom_lineitem_2 === false) : j.custom_lineitem_2 === item.custom_lineitem_2) : true) &&
					(custHeader1.length && custHeader1[0].display ? (item.custom_header_1 === undefined ? (j.custom_header_1 === undefined || j.custom_header_1 === null || j.custom_header_1 === '' || j.custom_header_1 === false) : j.custom_header_1 === item.custom_header_1) : true) &&
					(custHeader2.length && custHeader2[0].display ? (item.custom_header_2 === undefined ? (j.custom_header_2 === undefined || j.custom_header_2 === null || j.custom_header_2 === '' || j.custom_header_2 === false) : j.custom_header_2 === item.custom_header_2) : true));
			}
			if (newindexExcusion === -1 || newindexExcusion === itemIndex) {
				returnVal = true;
			}
			return returnVal;
		} else {
			return true;
		}
	}

	public doAddExclusionToListTab() {
		let isSaveCommission: boolean = false;

		// Territory drop-down-list
		if (this.selectedTerritoryData && this.selectedTerritoryData.length > 0 && this.selectedProductData && this.selectedProductData.length > 0) {
			this.selectedTerritoryData.forEach(territory => {
				this.selectedProductData.forEach(element => {
					let listOfSpecificUoms = [];
					if (!element.allproducts) {
						const priceListNum = territory.priceListNum || '';
						listOfSpecificUoms = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, element, priceListNum);
					}
					const itemOrGroup = this.exclusionsGroupRadioButton === 'addproductexclusion' ? 'item' : 'group';
					const defaultTierBasis = this.listOfTierBasis[0].code;
					const defaultCommissionBasis = this.listOfCommissionBasis[0].code;
					let defaultSelectedUom = '';
					let defaultListPrice = 0;
					if (listOfSpecificUoms.length > 0) {
						defaultSelectedUom = listOfSpecificUoms[0].code || '';
						defaultListPrice = listOfSpecificUoms[0].price || 0;
					}
					const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
					//exelusionTierbaseModel.guid = territory.guid + ' - ' + element.erp_primary_key || '';
					exelusionTierbaseModel.guid = this.guid.newGuid();
					exelusionTierbaseModel.territoryguid = territory.guid;
					exelusionTierbaseModel.territoryname = territory.territory_name;
					exelusionTierbaseModel.allterritories = territory.allterritories;
					exelusionTierbaseModel.itemsku = element.itemsku;
					exelusionTierbaseModel.itemType = element.type;
					exelusionTierbaseModel.itemOrGroup = itemOrGroup;
					exelusionTierbaseModel.erp_primary_key = element.erp_primary_key;
					exelusionTierbaseModel.itemname = element.itemname;
					exelusionTierbaseModel.allproducts = element.allproducts;
					exelusionTierbaseModel.allcustomergroups = element.allcustomergroups;
					exelusionTierbaseModel.mycustomers = element.mycustomers;
					exelusionTierbaseModel.description = element.description;
					exelusionTierbaseModel.tier_basis = defaultTierBasis;// Value Could be "volume" | "revenue" | "notiers"
					exelusionTierbaseModel.commission_basis = defaultCommissionBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
					exelusionTierbaseModel.uomlist = !element.allproducts ? listOfSpecificUoms : '';
					exelusionTierbaseModel.selectedUom = !element.allproducts && defaultCommissionBasis === 'dollarperuom' ? defaultSelectedUom : '';
					exelusionTierbaseModel.listPrice = +defaultListPrice.toFixed(2);
					exelusionTierbaseModel.rate = null;
					exelusionTierbaseModel.commissionfinalprice = defaultListPrice;
					if (this.isCustomerAgeTiers) {
						exelusionTierbaseModel.customer_age_tiers = [];
					} else {
						exelusionTierbaseModel.tiers = [];
					}
					//check duplicate validation for exlusion tab list
					if (this.checkExclusionListDuplicateRecords(exelusionTierbaseModel)) {
						return false;
					}
					this.exclusionTabList.unshift(exelusionTierbaseModel);
					// Latest exlusions save on edit commission contract
				});
			})
		}
		// End Territory drop-down-list
		if (this.erp_type === ErpTypeValEnum.NETSUITE) {
			if (this.selectedCustomerData && this.selectedCustomerData.length > 0 && this.selectedClassesData && this.selectedClassesData.length > 0) {
				this.selectedCustomerData.forEach(customer => {
					this.selectedClassesData.forEach(element => {
						// let listOfSpecificUoms = [];
						// if (!element.allproducts) {
						// 	const priceListNum = customer.priceListNum || '';
						// 	listOfSpecificUoms = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, element, priceListNum);
						// }
						const itemOrGroup = this.exclusionsGroupRadioButton === 'addproductexclusion' ? 'item' : 'group';
						const defaultTierBasis = this.listOfTierBasis[0].code;
						const defaultCommissionBasis = this.listOfCommissionBasis[0].code;
						let defaultSelectedUom = '';
						let defaultListPrice = 0;
						// if (listOfSpecificUoms.length > 0) {
						// 	defaultSelectedUom = listOfSpecificUoms[0].code || '';
						// 	defaultListPrice = listOfSpecificUoms[0].price || 0;
						// }
						const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
						exelusionTierbaseModel.guid = this.guid.newGuid();;
						//exelusionTierbaseModel.guid = customer.guid + ' - ' + (element['classguid'] ? element['classguid'] : element['guid'] || '');
						exelusionTierbaseModel.clientguid = customer.guid;
						exelusionTierbaseModel.clientcode = customer.code ? customer.code.toString() : '';
						exelusionTierbaseModel.clientname = customer.name;
						exelusionTierbaseModel.allcustomers = customer.allcustomers;
						// exelusionTierbaseModel.itemsku = element.itemsku;
						// exelusionTierbaseModel.itemOrGroup = itemOrGroup;
						// exelusionTierbaseModel.erp_primary_key = element.erp_primary_key;
						// exelusionTierbaseModel.itemname = element.itemname;
						// exelusionTierbaseModel.allproducts = element.allproducts;
						// exelusionTierbaseModel.description = element.description;
						exelusionTierbaseModel.allcustomergroups = element.allcustomergroups;
						exelusionTierbaseModel.mycustomers = element.mycustomers;
						exelusionTierbaseModel.bytransactions = customer.bytransactions;
						exelusionTierbaseModel.sales_team_transactions = customer.sales_team_transactions;
						exelusionTierbaseModel.assignedcustomers = customer.assignedcustomers;
						exelusionTierbaseModel.allclasses = element.allclasses;
						exelusionTierbaseModel.classguid = element['classguid'] ? element['classguid'] : element['guid'];
						exelusionTierbaseModel.classid = element['classid'] ? element['classid'] : element['id'];
						exelusionTierbaseModel.classname = element['classname'] ? element['classname'] : element['name'];

						exelusionTierbaseModel.tier_basis = defaultTierBasis;// Value Could be "volume" | "revenue" | "notiers"
						exelusionTierbaseModel.commission_basis = defaultCommissionBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
						// exelusionTierbaseModel.uomlist = !element.allproducts ? listOfSpecificUoms : '';
						// exelusionTierbaseModel.selectedUom = !element.allproducts && defaultCommissionBasis === 'dollarperuom' ? defaultSelectedUom : '';
						exelusionTierbaseModel.listPrice = +defaultListPrice.toFixed(2);
						exelusionTierbaseModel.rate = null;
						exelusionTierbaseModel.commissionfinalprice = defaultListPrice;
						//exelusionTierbaseModel.tiers = [];

						if (this.isCustomerAgeTiers) {
							exelusionTierbaseModel.customer_age_tiers = [];
						} else {
							exelusionTierbaseModel.tiers = [];
						}
						//check duplicate validation for exlusion tab list
						if (this.checkExclusionListDuplicateRecords(exelusionTierbaseModel)) {
							this._ToastrService.info('Duplicate entry: an entry matching this customer, product, and custom fields already exists !', 'Info', { closeButton: true, tapToDismiss: true });
							return false;
						}
						isSaveCommission = true;
						this.exclusionTabList.unshift(exelusionTierbaseModel);
						// Latest exlusions save on edit commission contract
					});
				})
			}

			if (this.selectedCustomerData && this.selectedCustomerData.length > 0 && this.selectedProductData && this.selectedProductData.length > 0) {
				this.selectedCustomerData.forEach(customer => {
					this.selectedProductData.forEach(element => {
						let listOfSpecificUoms = [];
						if (!this.enable_classification_for_commision) {
							if (!element.allproducts) {
								const priceListNum = customer.priceListNum || '';
								listOfSpecificUoms = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, element, priceListNum);
							}
						}
						const itemOrGroup = this.exclusionsGroupRadioButton === 'addproductexclusion' ? 'item' : 'group';
						const defaultTierBasis = this.listOfTierBasis[0].code;
						const defaultCommissionBasis = this.listOfCommissionBasis[0].code;
						let defaultSelectedUom = '';
						let defaultListPrice = 0;
						if (!this.enable_classification_for_commision) {
							if (listOfSpecificUoms.length > 0) {
								defaultSelectedUom = listOfSpecificUoms[0].code || '';
								defaultListPrice = listOfSpecificUoms[0].price || 0;
							}
						}
						const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
						//exelusionTierbaseModel.guid = customer.guid + ' - ' + element.erp_primary_key || '';
						exelusionTierbaseModel.guid = this.guid.newGuid();
						exelusionTierbaseModel.clientguid = customer.guid;
						exelusionTierbaseModel.clientcode = customer.code ? customer.code.toString() : '';
						exelusionTierbaseModel.clientname = customer.name;
						exelusionTierbaseModel.allcustomers = customer.allcustomers;
						exelusionTierbaseModel.itemsku = element.itemsku;
						exelusionTierbaseModel.itemType = element.type;
						exelusionTierbaseModel.itemOrGroup = itemOrGroup;
						exelusionTierbaseModel.erp_primary_key = element.erp_primary_key;
						exelusionTierbaseModel.itemname = element.itemname;
						exelusionTierbaseModel.allproducts = element.allproducts;
						exelusionTierbaseModel.allcustomergroups = element.allcustomergroups;
						exelusionTierbaseModel.mycustomers = element.mycustomers;
						exelusionTierbaseModel.bytransactions = customer.bytransactions;
						exelusionTierbaseModel.sales_team_transactions = customer.sales_team_transactions;
						exelusionTierbaseModel.assignedcustomers = customer.assignedcustomers;
						exelusionTierbaseModel.description = element.description;
						exelusionTierbaseModel.tier_basis = defaultTierBasis;// Value Could be "volume" | "revenue" | "notiers"
						exelusionTierbaseModel.commission_basis = defaultCommissionBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
						if (!this.enable_classification_for_commision) {
							exelusionTierbaseModel.uomlist = !element.allproducts ? listOfSpecificUoms : '';
							exelusionTierbaseModel.selectedUom = !element.allproducts && defaultCommissionBasis === 'dollarperuom' ? defaultSelectedUom : '';
						}
						exelusionTierbaseModel.listPrice = +defaultListPrice.toFixed(2);
						exelusionTierbaseModel.rate = null;
						exelusionTierbaseModel.commissionfinalprice = defaultListPrice;
						//exelusionTierbaseModel.tiers = [];
						if (this.isCustomerAgeTiers) {
							exelusionTierbaseModel.customer_age_tiers = [];
						} else {
							exelusionTierbaseModel.tiers = [];
						}
						//check duplicate validation for exlusion tab list
						if (this.checkExclusionListDuplicateRecords(exelusionTierbaseModel)) {
							this._ToastrService.info('Duplicate entry: an entry matching this customer, product, and custom fields already exists !', 'Info', { closeButton: true, tapToDismiss: true });
							return false;
						}
						isSaveCommission = true;
						this.exclusionTabList.unshift(exelusionTierbaseModel);
						// Latest exlusions save on edit commission contract
					});
				});
			}
		} else {
			if (this.selectedCustomerData && this.selectedCustomerData.length > 0 && this.selectedProductData && this.selectedProductData.length > 0) {
				if (this.commissionDetails.tiers_type === 'single_items') {
					const listOfRows = [];
					this.selectedCustomerData.forEach(customer => {
						customer.items = this.selectedProductData
						this.selectedProductData.forEach(element => {
							let listOfSpecificUoms = [];
							if (!element.allproducts) {
								const priceListNum = customer.priceListNum || '';
								listOfSpecificUoms = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, element, priceListNum);
							}
							const itemOrGroup = this.exclusionsGroupRadioButton === 'addproductexclusion' ? 'item' : 'group';
							const defaultTierBasis = this.listOfTierBasis[0].code;
							const defaultCommissionBasis = this.listOfCommissionBasis[0].code;
							let defaultSelectedUom = '';
							let defaultListPrice = 0;
							if (listOfSpecificUoms.length > 0) {
								defaultSelectedUom = listOfSpecificUoms[0].code || '';
								defaultListPrice = listOfSpecificUoms[0].price || 0;
							}
							const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
							//exelusionTierbaseModel.guid = customer.guid + ' - ' + element.erp_primary_key || '';
							exelusionTierbaseModel.guid = this.guid.newGuid();
							exelusionTierbaseModel.clientguid = customer.guid;
							exelusionTierbaseModel.clientcode = customer.code ? customer.code.toString() : '';
							exelusionTierbaseModel.clientname = customer.name;
							exelusionTierbaseModel.allcustomers = customer.allcustomers;
							exelusionTierbaseModel.itemsku = element.itemsku;
							exelusionTierbaseModel.itemType = element.type;
							exelusionTierbaseModel.itemOrGroup = itemOrGroup;
							exelusionTierbaseModel.erp_primary_key = element.erp_primary_key;
							exelusionTierbaseModel.itemname = element.itemname;
							exelusionTierbaseModel.allproducts = element.allproducts;
							exelusionTierbaseModel.allcustomergroups = element.allcustomergroups;
							exelusionTierbaseModel.mycustomers = element.mycustomers;
							exelusionTierbaseModel.bytransactions = customer.bytransactions;
							exelusionTierbaseModel.sales_team_transactions = customer.sales_team_transactions;
							exelusionTierbaseModel.assignedcustomers = customer.assignedcustomers;
							exelusionTierbaseModel.description = element.description;
							exelusionTierbaseModel.tier_basis = defaultTierBasis;// Value Could be "volume" | "revenue" | "notiers"
							exelusionTierbaseModel.commission_basis = defaultCommissionBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
							exelusionTierbaseModel.uomlist = !element.allproducts ? listOfSpecificUoms : '';
							exelusionTierbaseModel.selectedUom = !element.allproducts && defaultCommissionBasis === 'dollarperuom' ? defaultSelectedUom : '';
							exelusionTierbaseModel.listPrice = +defaultListPrice.toFixed(2);
							exelusionTierbaseModel.rate = null;
							exelusionTierbaseModel.commissionfinalprice = defaultListPrice;
							// exelusionTierbaseModel.tiers = [];
							if (this.isCustomerAgeTiers) {
								exelusionTierbaseModel.customer_age_tiers = [];
							} else {
								exelusionTierbaseModel.tiers = [];
							}
							//check duplicate validation for exlusion tab list
							if (this.checkExclusionListDuplicateRecords(exelusionTierbaseModel)) {
								this._ToastrService.info('Duplicate entry: an entry matching this customer, product, and custom fields already exists !', 'Info', { closeButton: true, tapToDismiss: true });
								return false;
							}
							isSaveCommission = true;
							// this.exclusionTabList.unshift(exelusionTierbaseModel);
							listOfRows.unshift(exelusionTierbaseModel);
							// Latest exlusions save on edit commission contract
						});
					});
				} else {
					this.selectedCustomerData.forEach(customer => {
						const itemOrGroup = this.exclusionsGroupRadioButton === 'addproductexclusion' ? 'item' : 'group';
						const defaultTierBasis = this.listOfTierBasis[0].code;
						const defaultCommissionBasis = this.listOfCommissionBasis[0].code;

						const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
						//exelusionTierbaseModel.guid = customer.guid + ' - ' + element.erp_primary_key || '';
						exelusionTierbaseModel.guid = this.guid.newGuid();
						exelusionTierbaseModel.clientguid = customer.guid;
						exelusionTierbaseModel.clientcode = customer.code ? customer.code.toString() : '';
						exelusionTierbaseModel.clientname = customer.name;
						exelusionTierbaseModel.allcustomers = customer.allcustomers;
						exelusionTierbaseModel.itemsku = null
						exelusionTierbaseModel.itemType = null
						exelusionTierbaseModel.itemOrGroup = itemOrGroup;
						exelusionTierbaseModel.erp_primary_key = null;
						exelusionTierbaseModel.itemname = null;
						exelusionTierbaseModel.allproducts = null;
						exelusionTierbaseModel.allcustomergroups = null;
						exelusionTierbaseModel.mycustomers = null;
						exelusionTierbaseModel.description = null;
						exelusionTierbaseModel.bytransactions = customer.bytransactions || null;
						exelusionTierbaseModel.sales_team_transactions = customer.sales_team_transactions || null;
						exelusionTierbaseModel.assignedcustomers = customer.assignedcustomers || null;
						exelusionTierbaseModel.tier_basis = defaultTierBasis;// Value Could be "volume" | "revenue" | "notiers"
						exelusionTierbaseModel.commission_basis = defaultCommissionBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
						exelusionTierbaseModel.uomlist = null;
						exelusionTierbaseModel.selectedUom = null;
						exelusionTierbaseModel.listPrice = null;
						exelusionTierbaseModel.rate = null;
						exelusionTierbaseModel.commissionfinalprice = null;
						// exelusionTierbaseModel.tiers = [];
						if (this.isCustomerAgeTiers) {
							exelusionTierbaseModel.customer_age_tiers = [];
						} else {
							exelusionTierbaseModel.tiers = [];
						}

						exelusionTierbaseModel.items = [];
						this.selectedProductData.forEach(element => {
							let listOfSpecificUoms = [];
							let priceListNum = customer.priceListNum || '';
							if (!element.allproducts) {
								listOfSpecificUoms = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, element, priceListNum);
							}
							let defaultSelectedUom = '';
							let defaultListPrice = 0;
							if (listOfSpecificUoms.length > 0) {
								defaultSelectedUom = listOfSpecificUoms[0].code || '';
								defaultListPrice = listOfSpecificUoms[0].price || 0;
							}
							const item = {
								// allproducts: element.allproducts,
								// allcustomergroups: element.allcustomergroups,
								// mycustomers: element.mycustomers,
								itemsku: element.itemsku,
								itemType: element.type,
								itemOrGroup: itemOrGroup,
								erp_primary_key: element.erp_primary_key,
								itemname: element.itemname,
								description: element.description,
								uomgroupentry: element.uomgroupentry,
								// uomlist: !element.allproducts ? listOfSpecificUoms : '',
								// selectedUom: !element.allproducts && defaultCommissionBasis === 'dollarperuom' ? defaultSelectedUom : '',
								// defaultSelectedUom: defaultSelectedUom,
								// defaultListPrice: defaultListPrice,
								// listPrice: +defaultListPrice.toFixed(2),
								// commissionfinalprice: defaultListPrice,
								// rate: null,
							};
							exelusionTierbaseModel.items.push(item);
						});
						//check duplicate validation for exlusion tab list
						if (this.checkExclusionListDuplicateRecords(exelusionTierbaseModel)) {
							this._ToastrService.info('Duplicate entry: an entry matching this customer, product, and custom fields already exists !', 'Info', { closeButton: true, tapToDismiss: true });
							return false;
						}
						isSaveCommission = true;
						this.exclusionTabList.unshift(exelusionTierbaseModel);
					});
				}
			}
			if (this.selectedCustomerGroupData && this.selectedCustomerGroupData.length > 0 && this.selectedCustomerGroupData && this.selectedCustomerGroupData.length > 0) {
				this.selectedCustomerGroupData.forEach(group => {
					this.selectedProductData.forEach(element => {
						let listOfSpecificUoms = [];
						if (!element.allproducts) {
							listOfSpecificUoms = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, element);
						}
						const itemOrGroup = this.exclusionsGroupRadioButton === 'addproductexclusion' ? 'item' : 'group';
						const defaultTierBasis = this.listOfTierBasis[0].code;
						const defaultCommissionBasis = this.listOfCommissionBasis[0].code;
						let defaultSelectedUom = '';
						let defaultListPrice = 0;
						if (listOfSpecificUoms.length > 0) {
							defaultSelectedUom = listOfSpecificUoms[0].code || '';
							defaultListPrice = listOfSpecificUoms[0].price || 0;
						}
						const exelusionTierbaseModel = new ExclusionTierbaseTabModel();
						//exelusionTierbaseModel.guid = group.guid + ' - ' + element.erp_primary_key || '';
						exelusionTierbaseModel.guid = this.guid.newGuid();
						exelusionTierbaseModel.clientguid = group.guid;
						exelusionTierbaseModel.clientcode = '';
						exelusionTierbaseModel.clientname = group.group_name;
						exelusionTierbaseModel.allcustomergroups = group.allcustomergroups;
						exelusionTierbaseModel.allcustomers = element.allcustomers;
						exelusionTierbaseModel.itemsku = element.itemsku;
						exelusionTierbaseModel.itemType = element.type;
						exelusionTierbaseModel.itemOrGroup = itemOrGroup;
						exelusionTierbaseModel.erp_primary_key = element.erp_primary_key;
						exelusionTierbaseModel.itemname = element.itemname;
						exelusionTierbaseModel.allproducts = element.allproducts;
						exelusionTierbaseModel.mycustomers = element.mycustomers;
						exelusionTierbaseModel.bytransactions = element.bytransactions;
						exelusionTierbaseModel.sales_team_transactions = element.sales_team_transactions;
						exelusionTierbaseModel.description = element.description;
						exelusionTierbaseModel.tier_basis = defaultTierBasis;// Value Could be "volume" | "revenue" | "notiers"
						exelusionTierbaseModel.commission_basis = defaultCommissionBasis;  // Value could be "doller" | "percentage" | "dollerperuom"
						exelusionTierbaseModel.uomlist = !element.allproducts ? listOfSpecificUoms : '';
						exelusionTierbaseModel.selectedUom = !element.allproducts && defaultCommissionBasis === 'dollarperuom' ? defaultSelectedUom : '';
						exelusionTierbaseModel.listPrice = +defaultListPrice.toFixed(2);
						exelusionTierbaseModel.rate = null;
						exelusionTierbaseModel.commissionfinalprice = defaultListPrice;
						// exelusionTierbaseModel.tiers = [];
						if (this.isCustomerAgeTiers) {
							exelusionTierbaseModel.customer_age_tiers = [];
						} else {
							exelusionTierbaseModel.tiers = [];
						}
						//check duplicate validation for exlusion tab list
						if (this.checkExclusionListDuplicateRecords(exelusionTierbaseModel)) {
							this._ToastrService.info('Duplicate entry: an entry matching this customer, product, and custom fields already exists !', 'Info', { closeButton: true, tapToDismiss: true });
							return false;
						}
						isSaveCommission = true;
						this.exclusionTabList.unshift(exelusionTierbaseModel);
						// Latest exlusions save on edit commission contract
					});
				})
			}
		}
		setTimeout(() => {
			this.dataSourceExclusionTab = this.exclusionTabList;
		}, 50);

		if (isSaveCommission) {
			this.calSaveCommissionContract();
		}
		if (this.editCommissionGuid) {
			// this.saveCommissionContract(true);
		}
		this.clearExclusionAgreementTabDetails();
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		e.toolbarOptions.items.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.exclusionTabDataGridRef.instance.clearFilter();
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: "Filter",
					onClick: () => {
						this.exclusionTabDataGridRef.instance.option("filterBuilderPopup.visible", true);
					}
				}
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					text: "Export",
					onClick: (event: any) => {
						this.doExportToExcel(event);
					}
				}
			}
		);
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel(e) {
		if (this.exclusionTabDataGridRef.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'Commission_Customers_Items_Groups.xlsx';
		worksheet = workbook.addWorksheet('Customers-Items-Groups');

		exportDataGrid({
			worksheet: worksheet,
			component: this.exclusionTabDataGridRef.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'rate') {
						excelCell.value = '';
						if (gridCell.value) {
							if (gridCell.data && gridCell.data.commission_basis) {
								if (gridCell.data.commission_basis === 'dollar') {
									excelCell.value = this._CurrencyPipe.transform(gridCell.value || 0, this.globalCurrency);
								}
								if (gridCell.data.commission_basis === 'percentage') {
									excelCell.value = +(this._DecimalPipe.transform(gridCell.value || 0, this.decimalPointPercentageFormat)) + '%'
								}
							}
						}
					}
				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
		e.cancel = true;
	}

	// Method used to Display Commission basis Name in uppercase
	public getDisplayExprCommissionbasis(item) {
		if (!item) {
			return "";
		}
		return item.label.toString().toUpperCase();
	}

	// Method used to Display Tier basis Name in uppercase
	public getDisplayExprTierbasis(item) {
		if (!item) {
			return "";
		}
		return item.label.toString().toUpperCase();
	}

	// Method used to Display Uom Name in uppercase
	public getDisplayExprUomName(item) {
		if (!item) {
			return "";
		}
		return item.name.toString().toUpperCase();
	}

	// #region for Methods for Items Datagrid under Tiers Datagrid
	public getItemsByTiersDetails(element) {
		return element.data.items || [];
	}

	public doEditorPreparingItemTierItmTab(event, subParentData: any, parentData: any) {
		if (event && event['row']) {
			// if (event.dataField === 'rate') {
			// 	if (event.row.isEditing) {
			// 		const exclusionTabListCloneArr: any = [];
			// 		if (event.component.getVisibleRows().length > 0) {
			// 			event.component.getVisibleRows().forEach(element => {
			// 				exclusionTabListCloneArr.push(element.data);
			// 			});
			// 		}
			// 		this.exclusionTabListClone = JSON.parse(JSON.stringify(exclusionTabListCloneArr));
			// 	}
			// }
			const itemObject = event.row.data;
			if (event.dataField === 'selectedUom') {
				event.editorOptions.visible = false;
				event.editorOptions.disabled = true;
				let uomDataSource = [];
				event.editorOptions.dataSource = uomDataSource;
				if (!itemObject.allproducts) {
					if (parentData.data.commission_basis === 'dollarperuom') {
						event.editorOptions.visible = true;
						event.editorOptions.disabled = false;
						uomDataSource = [];
						const itemIndex = parentData.data.items.findIndex(item => item.erp_primary_key === itemObject.erp_primary_key);
						if (itemIndex !== -1) {
							const selectedItemObject = parentData.data.items[itemIndex];
							uomDataSource = this._CommissionService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, selectedItemObject);
						}
						event.editorOptions.dataSource = uomDataSource;
					} else {
						event.editorOptions.visible = false;
						event.editorOptions.disabled = true;
						event.editorOptions.dataSource = [];
					}
				}
			}

			// if (event.dataField === 'tier_basis') {
			// 	let listOfTierBasis = this.listOfTierBasis;
			// 	event.editorOptions.dataSource = listOfTierBasis;
			// }

			if (event.dataField === 'commission_basis') {
				let listOfCommissionBasis = this.listOfCommissionBasis;
				if (itemObject.allproducts) {
					listOfCommissionBasis = this.listOfCommissionBasis.filter(rb => rb.code === 'dollar' || rb.code === 'percentage');
				}

				if (itemObject['itemType']) {
					if (itemObject['itemType'] === 'OtherCharge' || itemObject['itemType'] === 'Service') {
						listOfCommissionBasis = this.listOfCommissionBasis.filter(rb => rb.code === 'dollar' || rb.code === 'percentage');
					}
				}
				event.editorOptions.dataSource = listOfCommissionBasis;
			}

			if (event.dataField === 'rate') {
				event.editorOptions.visible = true;
				if (itemObject.allproducts) {
					if (parentData.data.tier_basis === 'notiers') {
						event.editorOptions.visible = false;
					}
				} else {
					if (parentData.data.tier_basis === 'notiers') {
						event.editorOptions.visible = false;
					}
				}
			}
		}
	}

	public doRowRemovedItemTierItmTab(event, subParentData: any, parentData: any) {
		this.calSaveCommissionContract();
	}

	public doRowUpdatedItemTierItmTab(event, subParentData: any, parentData: any) {
		this.calSaveCommissionContract();
	}

	public doOnSavedItemTierItmTab(event, subParentData: any, parentData: any) {
		this.doConvertDecimalPointByConfiguration();
		if (this.tiersItemDetailssDataGridRef) {
			this.tiersItemDetailssDataGridRef.instance.refresh();
		}
		this.calSaveCommissionContract();
	}

	public doOpenedDropdownItemTierItmTab(e) {
		this.itemDataGrid.instance.clearFilter();
	}

	public doItemSelectionChanged(event: any, cellInfo: any, dropDownBoxComponent) {
		const selectedRowKeys = event.selectedRowKeys;
		cellInfo.setValue(selectedRowKeys[0]);
		if (selectedRowKeys.length > 0) {
			// this.rowIndexForItemDatagrid = cellInfo.rowIndex;
			// this.selectedItemForDatagrid = event.selectedRowsData[0];
			// this.setCellValueForItems(event.selectedRowsData[0]);
			dropDownBoxComponent.close();
		}
	}

	// #endregion

	private doConvertDecimalPointByConfiguration() {
		if (this.exclusionTabList && this.exclusionTabList.length > 0) {
			for (let i = 0; i < this.exclusionTabList.length; i++) {
				const exList = this.exclusionTabList[i];
				if (exList.commission_basis === 'percentage') {
					if (exList.tiers && exList.tiers.length > 0) {
						for (let j = 0; j < exList.tiers.length; j++) {
							const t = exList.tiers[j];
							if (t.rate !== null) {
								const rate = +(this._DecimalPipe.transform(t.rate, this.decimalPointPercentageFormat));
								t.rate = rate;
							}
						}
					}
					if (exList.hasOwnProperty('rate')) {
						if (exList.rate !== null) {
							exList.rate = +(this._DecimalPipe.transform(exList.rate, this.decimalPointPercentageFormat))
						}
					}
				}
			}
		}
	}

	public doOpenFilterBuilder() {
		this.exclusionTabDataGridRef.instance.option("filterBuilderPopup.visible", true);
	}

	public saveCommissionCustomerTiersData(event) {
		this.calSaveCommissionContract();
	}
	// Method used to Fetch client list
	public getCustomerData() {
		this._AppCommonSrvc.fetchCustomerData(NameFromServerKeyEnum.CLIENT).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
						const customerData = response.data || [];
						const objcustomer = customerData[0];
						if (objcustomer && objcustomer.human_readable_account_code) {
							this.customerGridBoxColumns.push({ dataField: 'human_readable_account_code', caption: 'Code', visible: true })
							this.customerGridBoxColumns.forEach(element => {
								if (element.dataField == 'code') {
									element.visible = false;
								}
							});
							this.array_move(this.customerGridBoxColumns, 2, 0);
						}
					}
				}
			}, error: (error) => {
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	private array_move(arr, old_index, new_index) {
		if (new_index >= arr.length) {
			var k = new_index - arr.length + 1;
			while (k--) {
				arr.push(undefined);
			}
		}
		arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
		return arr; // for testing
	}

	// Method used to get Custom fileds for invoice
	private doGetFeatureCustomFields() {
		const formData = new FormData();
		let searchParams: any[] = [['is_contract_field_use_for_creation', '=', true], 'and', ['contract_type', '=', 'commission']];
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.FEATURE_CUSTOM_FIELDS);
		if (this.enable_profit_on_document_header) {
			formData.append('filter', JSON.stringify(searchParams));
		}
		if (this.getFeatureCustomFieldsLstSbn) {
			this.getFeatureCustomFieldsLstSbn.unsubscribe();
		}
		this.getFeatureCustomFieldsLstSbn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
						this.listOfFeatureCustomFields = response.data;
						this.isViewExlusionTabGrid = true;
					} else {
						this.isViewExlusionTabGrid = true;
					}
				} else {
					this.isViewExlusionTabGrid = true;
				}
			}, error: (error) => {
				this.isViewExlusionTabGrid = true;
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	public exclusionGridCustomizeColumns(columns, DataType) {
		if (this.enable_invoice_custom_fields_feature) {
			if (columns && columns.length > 0) {
				const actionColIndex = columns.findIndex(i => i.caption === 'Action');
				if (actionColIndex !== -1) {
					columns[actionColIndex].visibleIndex = columns.length; // action column set on last index in grid-data
				}
			}
		}
	}

	public initializedInvoiceDataGrid(event) {
		setTimeout(() => {
			if (this.enable_invoice_custom_fields_feature || this.enable_profit_on_document_header) {
				const tableColLen = event && event.component ? event.component.getVisibleColumns().length : 0;
				const customFieldDataList = this._CommissionService.getCustomFieldForInvoice(this.listOfFeatureCustomFields, tableColLen, this.customCurrencyOption);
				// if (this.exclusionTabDataGridRef && this.exclusionTabDataGridRef['instance']) {
				if (event.component.columnCount() > 0) {
					customFieldDataList.forEach(element => {
						event.component.addColumn(element);
					});
				}
				// }
			}
		}, 500);
	}

	public calSaveCommissionContract() {
		this.saveCommissionExclusionEvent.emit('save');
	}
	/*
	public doCustomizeTextTierMinMax(cellInfo: any, parentData?: any) {
		if (!cellInfo.value) {
			return cellInfo.valueText;
		} else if (cellInfo.value === '-') {
			return cellInfo.valueText;
		} else {
			return this._CurrencyPipe.transform((+cellInfo.value || 0), this.globalCurrency);
		}
	}
	*/
	public doCustomizeTextTierMinMax(cellInfo: any, parentData: any, which_tier: string) {
		if (which_tier === 'maxtier') {
			if (!cellInfo) {
				return cellInfo;
			} else if (cellInfo === '-') {
				return cellInfo;
			} else {
				if (parentData.data.tier_basis === 'volume') {
					return cellInfo;
				} else if (parentData.data.tier_basis === 'revenue') {
					return this._CurrencyPipe.transform((+cellInfo || 0), this.globalCurrency);
				} else {
					return cellInfo;
				}
			}
		}
		if (which_tier === 'mintier') {
			if (parentData.data.tier_basis === 'volume') {
				return cellInfo;
			} else if (parentData.data.tier_basis === 'revenue') {
				return this._CurrencyPipe.transform((+cellInfo || 0), this.globalCurrency);
			} else {
				return cellInfo;
			}
		}
	}
}
