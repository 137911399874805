<div class="rightBar">
	<div class="rightBlock">
		<div class="contentRow ">
			<div class="borderBox cf">
				<div class="tableOut">
					<dx-data-grid #dataGridContainer id="gridContainer" [dataSource]="commissionContractDataSource"
						[showBorders]="true" [showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
						[filterSyncEnabled]="true" [filterValue]="filterValue" [remoteOperations]="{ groupPaging: true }"
						[columnAutoWidth]="true" [width]="'100%'" (onToolbarPreparing)="doToolbarPreparing($event)"
						(onRowClick)="doEditCommissionContract($event.data);" class="cellColor hoverClr hideFilterPanelInx"
						(onCellClick)="doCellClickDetails($event)">
						<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
						<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
						<dxo-pager [visible]="true" [showPageSizeSelector]="true"
							[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
						</dxo-pager>
						<dxo-filter-row [visible]="true"></dxo-filter-row>
						<dxo-filter-panel [visible]="true"></dxo-filter-panel>
						<dxo-header-filter [visible]="true"></dxo-header-filter>
						<dxo-sorting mode="single"></dxo-sorting>

						<dxo-group-panel [visible]="false"></dxo-group-panel>
						<dxo-filter-row [visible]="true"></dxo-filter-row>
						<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
						<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadAgreementTemplateState"
							[customSave]="doSaveAgreementTemplateState" [storageKey]="stateStorageKey">
						</dxo-state-storing>
						<!-- <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping> -->

						<div *dxTemplate="let data of 'templateFilterText'">
							<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
								<span>{{filterText}}</span>
							</div>
						</div>

						<div *dxTemplate="let data of 'templateActionBtns'">
							<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false"
								[showArrowIcon]="false" text="Action"
								class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
								[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code"
								icon="spindown" (onButtonClick)="doActionsBtnClick($event)"
								(onItemClick)="doActionsBtnItemClick($event)"
								[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
						</div>

						<dxi-column dataField="template_name" caption="Name" dataType="string" [allowHeaderFiltering]="false">
						</dxi-column>

						<dxi-column dataField="contract_type" caption="Contract Type" dataType="string"
							[allowHeaderFiltering]="true" [allowFiltering]="false"
							[calculateCellValue]="doCalculateCellValueContractType">
							<dxo-header-filter [dataSource]="listOfContractTypes"> </dxo-header-filter>
						</dxi-column>

						<dxi-column dataField="settlementPeriod" caption="Settlement period" dataType="string"
							[calculateCellValue]="doCalculateCellValueSettlementPeriod" [allowFiltering]="false"
							[allowHeaderFiltering]="true">
							<dxo-header-filter [dataSource]="listOfSettlementPeriods"> </dxo-header-filter>

						</dxi-column>

						<dxi-column dataField="calculationOnBasis" caption="Calculation Basis" dataType="string"
							[allowHeaderFiltering]="true" [allowFiltering]="false"
							[calculateCellValue]="doCalculateCellValueCalculationOnBasis">
							<dxo-header-filter [dataSource]="listOfCalculationOnBasis"> </dxo-header-filter>
						</dxi-column>

						<dxi-column dataField="calculate_commission_by" caption="Commission By" dataType="string"
							[allowHeaderFiltering]="true" [allowFiltering]="false"
							[calculateCellValue]="doCalculateCellValueCalculateCommissionBy">
							<dxo-header-filter [dataSource]="listOfCalculateCommissionBy"> </dxo-header-filter>
						</dxi-column>

						<!-- <dxi-column dataField="commissionPercent" alignment="center" caption=" Commission (%)"
							dataType="number" [allowHeaderFiltering]="false">
						</dxi-column> -->

						<dxi-column dataField="is_active" [allowFiltering]="false" [allowHeaderFiltering]="true"
							[allowHeaderFiltering]="true" alignment="center" caption="Status" dataType="string"
							cellTemplate="cellTemplateStatus" [visible]="isShowAction">
							<dxo-header-filter [dataSource]="listOfStatusHeaderFilter">
							</dxo-header-filter>
							<div *dxTemplate="let element of 'cellTemplateStatus'">
								<dx-drop-down-button [splitButton]="false"
									[ngClass]="getClassNameForStatusField(element.data.is_active)" [useSelectMode]="false"
									[text]="element.data.is_active ? 'ACTIVE' : 'INACTIVE'" stylingMode="contained"
									class="dynamicBgColors secondaryBtn dynamicarow ix-textleftalign" [items]="listOfStatuses"
									[wrapItemText]="true" [useSelectMode]="true" displayExpr="label" keyExpr="value"
									(onButtonClick)="getListOfStatus(element.data)"
									(onItemClick)="changedCommissionContractStatusAction(element.data.guid,!element.data.is_active)"
									[width]="'100%'">
								</dx-drop-down-button>
							</div>
						</dxi-column>

						<dxi-column caption="Action" [allowHiding]="false" [fixed]="true" fixedPosition="right"
							alignment="center" dataType="string" cellTemplate="cellTemplateAction" [allowExporting]="false"
							[allowResizing]="false" [visible]="isShowAction" [allowFiltering]="false" [allowSorting]="false"
							[width]="90">
							<div *dxTemplate="let element of 'cellTemplateAction'" class="center actBtn actBtnBigTooltip">
								<!-- <a matTooltip="Edit" (click)="editCommissionContract(element.data);$event.stopPropagation();">
									<span class="icon-editdxtable"></span>
								</a> -->
								<!-- <a matTooltip="Create Contract From Template"
									(click)="createCommissionContract(element.data);$event.stopPropagation();">
									<span class="addIcon sprite"></span>
								</a> -->
								<a id="createcontracttemplate{{element.rowIndex + 1}}"
									(click)="doCreateMultipleCommissionContracts(element.data);$event.stopPropagation();">
									<span class="dx-icon-addaction"></span>
								</a>
								<dx-tooltip target="#createcontracttemplate{{element.rowIndex + 1}}" showEvent="dxhoverstart"
									position="bottom" hideEvent="dxhoverend">
									<div *dxTemplate="let data = data of 'content'">
										Create Contract From Template
									</div>
								</dx-tooltip>
								<a id="copyContract_{{element.rowIndex + 1}}"
									(click)="copyCommissionContract(element.data);$event.stopPropagation();">
									<span class="icon-copydxtable"></span>
								</a>
								<dx-tooltip target="#copyContract_{{element.rowIndex + 1}}" showEvent="dxhoverstart"
									hideEvent="dxhoverend" position="bottom">
									<div *dxTemplate="let data = data of 'content'">
										Copy
									</div>
								</dx-tooltip>
							</div>
							<dxo-filter-row [visible]="false"></dxo-filter-row>
							<dxo-header-filter [visible]="false"></dxo-header-filter>
						</dxi-column>

					</dx-data-grid>
				</div>
			</div>
		</div>
	</div>
</div>

<app-sales-rep-list-dialog *ngIf="isShowMultipleContractPopup" [multipleContractDetailsProps]="multipleContractDetails"
	(closeMultipleContractPopup)="doCloseMultipleContractPopup($event)"></app-sales-rep-list-dialog>