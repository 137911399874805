<div id="royaltylists" class="rightBar">
	<div class="rightBlock">
		<div class="contentRow cf">
			<div class="borderBox cf">
				<div class="tableOut">

					<dx-data-grid #dataGridContainer id="gridContainer" [dataSource]="royaltiesContractDataSource"
						[showBorders]="true" [showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
						[filterSyncEnabled]="true" [dateSerializationFormat]="'yyyy-MM-dd'" [remoteOperations]="true"
						[columnAutoWidth]="true" [allowColumnReordering]="true" [width]="'100%'"
						(onToolbarPreparing)="doToolbarPreparing($event)" (onRowClick)="editRoyaltiesContract($event.data);"
						class="cellColor hoverClr hideFilterPanelInx" (onCellClick)="doCellClickDetails($event)">
						<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
						<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
						<dxo-pager [visible]="true" [showPageSizeSelector]="true"
							[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
						</dxo-pager>
						<dxo-filter-row [visible]="true"></dxo-filter-row>
						<dxo-filter-panel [visible]="true"></dxo-filter-panel>
						<dxo-header-filter [visible]="true"></dxo-header-filter>
						<dxo-sorting mode="single"></dxo-sorting>
						<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
						<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadRoyaltiesState"
							[customSave]="doSaveRoyaltiesState" [storageKey]="stateStorageKey">
						</dxo-state-storing>
						<div *dxTemplate="let data of 'templateFilterText'">
							<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
								<span>{{filterText}}</span>
							</div>
						</div>

						<div *dxTemplate="let data of 'templateActionBtns'">
							<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false"
								[showArrowIcon]="false" text="Action"
								class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
								[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code"
								icon="spindown" (onButtonClick)="doActionsBtnClick($event)"
								(onItemClick)="doActionsBtnItemClick($event)"
								[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
						</div>

						<div *dxTemplate="let data of 'templateUpdateCurrentSettlementBtn'">
							<dx-button class="secondaryBtn toolbarbtnix" type="normal" stylingMode="contained"
								(onClick)="doUpdateCurrentSettlementPeriods()" [useSubmitBehavior]="false">
								<div *dxTemplate="let data of 'content'">
									<dx-load-indicator height="25" width="25" class="button-indicator"
										[elementAttr]="{ class: 'button-loader-indicator-blue' }"
										[visible]="isShowLoaderIndicator"></dx-load-indicator>
									<span class="dx-button-text"
										[class]="{'mrgL10':isShowLoaderIndicator}">{{updateSettlementPeriodBtnText}}</span>
								</div>
							</dx-button>
						</div>

						<dxi-column dataField="contractName" caption="Name" dataType="string" [allowHeaderFiltering]="false">
						</dxi-column>

						<dxi-column dataField="selectVendor" caption="Vendor" dataType="string" [allowFiltering]="false"
							[allowHeaderFiltering]="true">
						</dxi-column>

						<dxi-column dataField="settlementPeriod" [allowFiltering]="false" [allowHeaderFiltering]="true"
							alignment="center" caption="Settlement Period" dataType="string"
							cellTemplate="cellTemplateSettlementPeriod">
							<dxo-header-filter [dataSource]="listOfSettlementPeriodHeaderFilter">
							</dxo-header-filter>
							<div *dxTemplate="let element of 'cellTemplateSettlementPeriod'">
								{{element.data.settlementPeriod === 'halfYearly' ? 'Bi-annually': element.data.settlementPeriod
								=== 'yearly' ? 'Annually' : element.data.settlementPeriod | titlecase}}
							</div>
						</dxi-column>

						<dxi-column dataField="startDate" alignment="center" caption="Contract Start Date" dataType="date"
							format="yyyy-MM-dd" cellTemplate="cellTemplateStartDate" [allowHeaderFiltering]="false">
							<div *dxTemplate="let element of 'cellTemplateStartDate'">
								{{ (element.data.startDate !== null && element.data.startDate !== '' && element.data.startDate
								!== undefined && element.data.startDate !== 'Invalid date') ? (element.data.startDate | date:
								'yyyy-MM-dd') : "" }}
							</div>
						</dxi-column>

						<dxi-column dataField="endDate" alignment="center" caption="Contract End Date" dataType="date"
							format="yyyy-MM-dd" cellTemplate="cellTemplateEndDate" [allowHeaderFiltering]="false">
							<div *dxTemplate="let element of 'cellTemplateEndDate'">
								{{ (element.data.endDate !== null && element.data.endDate !== '' && element.data.endDate !==
								undefined && element.data.endDate !== 'Invalid date') ? (element.data.endDate | date:
								'yyyy-MM-dd') : "" }}
							</div>
						</dxi-column>

						<dxi-column dataField="settlementPeriodStart" alignment="center" caption="Open Settlement Start Date"
							dataType="date" format="yyyy-MM-dd" cellTemplate="cellTemplateOpenSettlementStartDate"
							[allowHeaderFiltering]="false">
							<div *dxTemplate="let element of 'cellTemplateOpenSettlementStartDate'">
								{{ (element.data.settlementPeriodStart !== null && element.data.settlementPeriodStart !== '' &&
								element.data.settlementPeriodStart
								!== undefined && element.data.settlementPeriodStart !== 'Invalid date') ?
								(element.data.settlementPeriodStart | date:
								'yyyy-MM-dd') : "" }}
							</div>
						</dxi-column>

						<dxi-column dataField="settlementPeriodEnd" alignment="center" caption="Open Settlement End Date"
							dataType="date" format="yyyy-MM-dd" cellTemplate="cellTemplateOpenSettlementEndDate"
							[allowHeaderFiltering]="false">
							<div *dxTemplate="let element of 'cellTemplateOpenSettlementEndDate'">
								{{ (element.data.settlementPeriodEnd !== null && element.data.settlementPeriodEnd !== '' &&
								element.data.settlementPeriodEnd !==
								undefined && element.data.settlementPeriodEnd !== 'Invalid date') ?
								(element.data.settlementPeriodEnd | date:
								'yyyy-MM-dd') : "" }}
							</div>
						</dxi-column>

						<dxi-column dataField="current_settlement_commission_total" caption="Settlement Amount"
							dataType="number" [allowHeaderFiltering]="false" [allowFiltering]="false">
							<dxo-format type="currency" [precision]="decimalPointForCurrency">
							</dxo-format>
							<div *dxTemplate="let element of 'cellTemplateSettlementAmount'">
								{{ element.data.current_settlement_commission_total || 0 | customCurrencyPipe}}
							</div>
						</dxi-column>

						<dxi-column dataField="contractCalendar" caption="Contract Calendar" dataType="string"
							cellTemplate="cellTemplateContractCalendar" [allowFiltering]="false" [allowHeaderFiltering]="true">
							<dxo-header-filter [dataSource]="listOfContractCalendar"></dxo-header-filter>
							<div *dxTemplate="let element of 'cellTemplateContractCalendar'">
								{{ element.data.contractCalendar || '' |filterFromList:listOfContractCalendar : 'value' : 'text'
								}}
							</div>
						</dxi-column>

						<!-- <dxi-column dataField="royaltiesPercent" alignment="center" caption=" Royalty (%)" dataType="number"
							[allowHeaderFiltering]="false">
						</dxi-column> -->

						<dxi-column dataField="is_active" [allowFiltering]="false" [allowHeaderFiltering]="true"
							alignment="center" caption="Status" dataType="boolean" cellTemplate="cellTemplateStatus">
							<dxo-header-filter [dataSource]="listOfStatusHeaderFilter">
							</dxo-header-filter>
							<div *dxTemplate="let element of 'cellTemplateStatus'">
								<dx-drop-down-button [splitButton]="false" [disabled]="isDisabledToModifyStatus"
									[ngClass]="getClassNameForStatusField(element.data.is_active)" [useSelectMode]="false"
									[text]="element.data.is_active ? 'ACTIVE' : 'INACTIVE'" stylingMode="contained"
									class="dynamicBgColors secondaryBtn dynamicarow ix-textleftalign" [items]="listOfStatuses"
									[wrapItemText]="true" [useSelectMode]="true" displayExpr="label" keyExpr="value"
									(onButtonClick)="getListOfStatus(element.data)"
									(onItemClick)="changedRoyaltiesContractStatusAction(element.data.guid,!element.data.is_active)"
									[width]="'100%'">
								</dx-drop-down-button>
							</div>
						</dxi-column>

						<dxi-column caption="Action" alignment="center" dataType="string" [allowHiding]="false" [fixed]="true"
							fixedPosition="right" cellTemplate="cellTemplateAction" [allowExporting]="false"
							[visible]="isShowAction" [allowFiltering]="false" [allowSorting]="false" [allowResizing]="false"
							[width]="75">
							<div *dxTemplate="let element of 'cellTemplateAction'" class="center actBtn">
								<a [attr.id]="'copyContract_'+element.data.guid" (click)="doCopyRoyaltyContract(element.data);">
									<span class="icon-copydxtable"></span>
								</a>
								<dx-tooltip target="#copyContract_{{element.data.guid}}" showEvent="dxhoverstart"
									hideEvent="dxhoverend" position="bottom">
									<div *dxTemplate="let data = data of 'content'">
										Copy
									</div>
								</dx-tooltip>
							</div>
						</dxi-column>

						<dxo-summary>
							<dxi-total-item column="current_settlement_commission_total" summaryType="sum" displayFormat="{0}">
								<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
								</dxo-value-format>
							</dxi-total-item>
						</dxo-summary>

					</dx-data-grid>
				</div>
			</div>
		</div>
	</div>
</div>